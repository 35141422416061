import { gql } from '../../../__generated__';

export const LOGIN = gql(`mutation
  getUser($data: GetUserInput) {
  getUser(data: $data) {
    data {
      id
      phoneNumber
      userName
     }
    }
  }
`);

export const CREATE_PRIVATE_TABLE =
  gql(`mutation CreatePrivateTable($userId: String!) {
  createPrivateTable(userId: $userId) {
    data {
      id
    }
  }
}
`);

export const ADD_USER_TO_TABLE =
  gql(`mutation AddUsersToTable($addUsersToTableData: AddUsersToTableInput!) {
  addUsersToTable(data: $addUsersToTableData) {
        message
         data {
          slug
          tableId
          gameSessionUsers {
            gameSessionId

          }
        }
     }
  }
`);

export const ADD_MONEY_TO_WALLET =
  gql(`mutation addMoneyToWallet($data: AddMoneyInput) {
    addMoneyToWallet(data: $data) {
      data {
        balance
      }
      message
    }
}
`);

export const START_GAME = gql(`mutation StartGame($startGameId: String!) {
  startGame(id: $startGameId) {
    data {
        card {
          id
          name
        }
      userId
      cardId
    }
  }
}
`);

export const START_COUNTER =
  gql(`mutation StartCounter($data: StartCounterInput) {
  startCounter(data: $data) {
        __typename

  }
}
`);

export const PLACE_BID = gql(`mutation PlaceBid($data: PlaceBidInput) {
  placeBid (data: $data){
    data {
      amount
      cardId
      userId
    }
  }
}
`);

export const COMPLETE_CARD_AUCTION =
  gql(`mutation CompleteCardAuction($data: CompleteCardAuctionInputType) {
  completeCardAuction(data: $data) {
    message
    data{
    userId
    balance
    }
  }
}
`);

export const COMPLETE_GAME =
  gql(`mutation CompletGame($gameSessionId: String!) {
  completeGame(gameSessionId: $gameSessionId) {
    data {
      amount
      points
      position
      userId
    }
  }
}
`);
