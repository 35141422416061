/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  /** Limit custom scalar type */
  Limit: { input: any; output: any; }
};

export type AddMoneyInput = {
  amount: Scalars['Float']['input'];
  gameSessionId?: InputMaybe<Scalars['ID']['input']>;
  type: WalletHistoryTypeEnum;
  userId: Scalars['ID']['input'];
};

export type AddMoneyResponse = {
  __typename?: 'AddMoneyResponse';
  data?: Maybe<WalletType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AddUsersToTableInput = {
  tableId: Scalars['String']['input'];
  users: Array<InputMaybe<GameSessionUserInputType>>;
};

export type AddUsersToTableResponse = {
  __typename?: 'AddUsersToTableResponse';
  data?: Maybe<GameSessionUserWithTableDataType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type BidHistoryType = {
  __typename?: 'BidHistoryType';
  amount: Scalars['Float']['output'];
  cardId: Scalars['ID']['output'];
  gameSessionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type BidValuesType = {
  __typename?: 'BidValuesType';
  amount?: Maybe<Scalars['Int']['output']>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type CardType = {
  __typename?: 'CardType';
  copies?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CommonMessageResponse = {
  __typename?: 'CommonMessageResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type CompleteCardAuctionInputType = {
  amount: Scalars['Float']['input'];
  cardId: Scalars['ID']['input'];
  gameSessionId: Scalars['ID']['input'];
  tableCardId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CompleteCardAuctionResponseType = {
  __typename?: 'CompleteCardAuctionResponseType';
  data?: Maybe<WalletType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CompleteGameData = {
  __typename?: 'CompleteGameData';
  amount?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['ID']['output'];
};

export type CompleteGameResponseType = {
  __typename?: 'CompleteGameResponseType';
  data?: Maybe<Array<Maybe<CompleteGameData>>>;
  message?: Maybe<Scalars['String']['output']>;
  platformAmount?: Maybe<Scalars['Float']['output']>;
  potAmount?: Maybe<Scalars['Float']['output']>;
};

export enum ConfigKeyEnum {
  BidIncrementals = 'bid_incrementals',
  DefaultTimer = 'default_timer',
  GamePlayers = 'game_players',
  GameVariation = 'game_variation',
  InitialCardValue = 'initial_card_value',
  InitialWalletBalance = 'initial_wallet_balance',
  NumberOfRounds = 'number_of_rounds',
  PlatformCommission = 'platform_commission',
  TopUpWalletBalance = 'top_up_wallet_balance',
  WinningRatio = 'winning_ratio'
}

export type ConfigType = {
  __typename?: 'ConfigType';
  key?: Maybe<ConfigKeyEnum>;
  value?: Maybe<ConfigValueType>;
};

export type ConfigValueType = {
  __typename?: 'ConfigValueType';
  amount?: Maybe<Scalars['Float']['output']>;
  bidValues?: Maybe<Array<Maybe<BidValuesType>>>;
  default?: Maybe<Scalars['Int']['output']>;
  first?: Maybe<Scalars['Int']['output']>;
  isHalf?: Maybe<Scalars['Boolean']['output']>;
  isUniversal?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  round1?: Maybe<Scalars['Int']['output']>;
  round2?: Maybe<Scalars['Int']['output']>;
  round3?: Maybe<Scalars['Int']['output']>;
  second?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CreatePrivateTableResponse = {
  __typename?: 'CreatePrivateTableResponse';
  data?: Maybe<TableType>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum GameSessionStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  YetToStart = 'YET_TO_START'
}

export type GameSessionType = {
  __typename?: 'GameSessionType';
  endTime?: Maybe<Scalars['DateTime']['output']>;
  gameId?: Maybe<Scalars['ID']['output']>;
  gameSessionUsers?: Maybe<Array<Maybe<GameSessionUsersType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  potAmount?: Maybe<Scalars['Float']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<GameSessionStatusEnum>;
  tableId?: Maybe<Scalars['ID']['output']>;
};

export type GameSessionUserInputType = {
  userId: Scalars['String']['input'];
};

export type GameSessionUserWithTableDataType = {
  __typename?: 'GameSessionUserWithTableDataType';
  gameSessionUsers?: Maybe<Array<Maybe<GameSessionUsersType>>>;
  slug?: Maybe<Scalars['String']['output']>;
  tableId: Scalars['ID']['output'];
};

export type GameSessionUsersType = {
  __typename?: 'GameSessionUsersType';
  gameId?: Maybe<Scalars['ID']['output']>;
  gameSessionId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isHost?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<UserType>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type GetAllCardResponseType = {
  __typename?: 'GetAllCardResponseType';
  data?: Maybe<Array<Maybe<CardType>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetAuctionCardInputType = {
  gameSessionId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type GetAuctionCardResponseType = {
  __typename?: 'GetAuctionCardResponseType';
  data?: Maybe<TableCardType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetConfigResponseType = {
  __typename?: 'GetConfigResponseType';
  data?: Maybe<Array<Maybe<ConfigType>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetGameSessionResponseType = {
  __typename?: 'GetGameSessionResponseType';
  data?: Maybe<GameSessionType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetGameSessionUsersResponseType = {
  __typename?: 'GetGameSessionUsersResponseType';
  data?: Maybe<Array<Maybe<GameSessionUsersType>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetLastBidInputType = {
  cardId: Scalars['ID']['input'];
  gameSessionId: Scalars['ID']['input'];
};

export type GetTableCardInputType = {
  gameSessionId: Scalars['ID']['input'];
};

export type GetTableCardResponseType = {
  __typename?: 'GetTableCardResponseType';
  data?: Maybe<Array<Maybe<TableCardType>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetTablesResponse = {
  __typename?: 'GetTablesResponse';
  data?: Maybe<Array<Maybe<TableType>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetUserGamePointsInputType = {
  gameSessionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GetUserGamePointsResponseType = {
  __typename?: 'GetUserGamePointsResponseType';
  data?: Maybe<GetUserGamePointsType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetUserGamePointsType = {
  __typename?: 'GetUserGamePointsType';
  cards?: Maybe<Array<Maybe<TableCardType>>>;
  points?: Maybe<Scalars['Int']['output']>;
};

export type GetUserInput = {
  phoneNumber: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type GetUserResponse = {
  __typename?: 'GetUserResponse';
  data?: Maybe<UserType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMoneyToWallet?: Maybe<AddMoneyResponse>;
  addUsersToTable?: Maybe<AddUsersToTableResponse>;
  completeCardAuction?: Maybe<CompleteCardAuctionResponseType>;
  completeGame?: Maybe<CompleteGameResponseType>;
  createPrivateTable?: Maybe<CreatePrivateTableResponse>;
  getUser?: Maybe<GetUserResponse>;
  placeBid?: Maybe<PlaceBidResponse>;
  startCounter?: Maybe<StartCounterResponse>;
  startGame?: Maybe<StartGameResponseType>;
};


export type MutationAddMoneyToWalletArgs = {
  data?: InputMaybe<AddMoneyInput>;
};


export type MutationAddUsersToTableArgs = {
  data: AddUsersToTableInput;
};


export type MutationCompleteCardAuctionArgs = {
  data?: InputMaybe<CompleteCardAuctionInputType>;
};


export type MutationCompleteGameArgs = {
  gameSessionId: Scalars['String']['input'];
};


export type MutationCreatePrivateTableArgs = {
  userId: Scalars['String']['input'];
};


export type MutationGetUserArgs = {
  data?: InputMaybe<GetUserInput>;
};


export type MutationPlaceBidArgs = {
  data?: InputMaybe<PlaceBidInput>;
};


export type MutationStartCounterArgs = {
  data?: InputMaybe<StartCounterInput>;
};


export type MutationStartGameArgs = {
  id: Scalars['String']['input'];
};

export type PlaceBidInput = {
  amount: Scalars['Float']['input'];
  cardId: Scalars['ID']['input'];
  gameSessionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PlaceBidResponse = {
  __typename?: 'PlaceBidResponse';
  data?: Maybe<BidHistoryType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  dynamicMessage?: Maybe<CommonMessageResponse>;
  getAllCards?: Maybe<GetAllCardResponseType>;
  getAuctionCard?: Maybe<GetAuctionCardResponseType>;
  getConfig?: Maybe<GetConfigResponseType>;
  getGameSession?: Maybe<GetGameSessionResponseType>;
  getGameSessionUsers?: Maybe<GetGameSessionUsersResponseType>;
  getLastBid?: Maybe<PlaceBidResponse>;
  getPrivateTables?: Maybe<GetTablesResponse>;
  getTableCards?: Maybe<GetTableCardResponseType>;
  getTables?: Maybe<GetTablesResponse>;
  getUserGamePoints?: Maybe<GetUserGamePointsResponseType>;
  getUserWallet?: Maybe<AddMoneyResponse>;
};


export type QueryDynamicMessageArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAuctionCardArgs = {
  data?: InputMaybe<GetAuctionCardInputType>;
};


export type QueryGetConfigArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetGameSessionArgs = {
  gameSessionId: Scalars['String']['input'];
};


export type QueryGetGameSessionUsersArgs = {
  gameSessionId: Scalars['String']['input'];
};


export type QueryGetLastBidArgs = {
  data?: InputMaybe<GetLastBidInputType>;
};


export type QueryGetTableCardsArgs = {
  data?: InputMaybe<GetTableCardInputType>;
};


export type QueryGetUserGamePointsArgs = {
  data: GetUserGamePointsInputType;
};


export type QueryGetUserWalletArgs = {
  userId: Scalars['ID']['input'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  key?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartCounterInput = {
  cardId: Scalars['ID']['input'];
  gameSessionId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type StartCounterResponse = {
  __typename?: 'StartCounterResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type StartGameResponseType = {
  __typename?: 'StartGameResponseType';
  data?: Maybe<Array<Maybe<TableCardType>>>;
  message?: Maybe<Scalars['String']['output']>;
};

export type TableCardType = {
  __typename?: 'TableCardType';
  amount?: Maybe<Scalars['Float']['output']>;
  card?: Maybe<CardType>;
  cardId: Scalars['ID']['output'];
  gameSessionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isInitial?: Maybe<Scalars['Boolean']['output']>;
  tableId: Scalars['ID']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum TableStatusEnum {
  Available = 'AVAILABLE',
  Ongoing = 'ONGOING'
}

export type TableType = {
  __typename?: 'TableType';
  createdBy?: Maybe<Scalars['String']['output']>;
  gameSessions?: Maybe<Array<Maybe<GameSessionType>>>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TableStatusEnum>;
  tableOrder?: Maybe<Scalars['Int']['output']>;
};

export type UserType = {
  __typename?: 'UserType';
  browser?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userAgent?: Maybe<Scalars['JSON']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  wallet?: Maybe<WalletType>;
};

export enum WalletHistoryTransactionEnum {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type WalletHistoryType = {
  __typename?: 'WalletHistoryType';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  transaction?: Maybe<WalletHistoryTransactionEnum>;
  type?: Maybe<WalletHistoryTypeEnum>;
  userId?: Maybe<Scalars['ID']['output']>;
  walletId?: Maybe<Scalars['ID']['output']>;
};

export enum WalletHistoryTypeEnum {
  GameSession = 'GAME_SESSION'
}

export type WalletType = {
  __typename?: 'WalletType';
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type GetUserMutationVariables = Exact<{
  data?: InputMaybe<GetUserInput>;
}>;


export type GetUserMutation = { __typename?: 'Mutation', getUser?: { __typename?: 'GetUserResponse', data?: { __typename?: 'UserType', id?: string | null, phoneNumber?: string | null, userName?: string | null } | null } | null };

export type CreatePrivateTableMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type CreatePrivateTableMutation = { __typename?: 'Mutation', createPrivateTable?: { __typename?: 'CreatePrivateTableResponse', data?: { __typename?: 'TableType', id: string } | null } | null };

export type AddUsersToTableMutationVariables = Exact<{
  addUsersToTableData: AddUsersToTableInput;
}>;


export type AddUsersToTableMutation = { __typename?: 'Mutation', addUsersToTable?: { __typename?: 'AddUsersToTableResponse', message?: string | null, data?: { __typename?: 'GameSessionUserWithTableDataType', slug?: string | null, tableId: string, gameSessionUsers?: Array<{ __typename?: 'GameSessionUsersType', gameSessionId?: string | null } | null> | null } | null } | null };

export type AddMoneyToWalletMutationVariables = Exact<{
  data?: InputMaybe<AddMoneyInput>;
}>;


export type AddMoneyToWalletMutation = { __typename?: 'Mutation', addMoneyToWallet?: { __typename?: 'AddMoneyResponse', message?: string | null, data?: { __typename?: 'WalletType', balance?: number | null } | null } | null };

export type StartGameMutationVariables = Exact<{
  startGameId: Scalars['String']['input'];
}>;


export type StartGameMutation = { __typename?: 'Mutation', startGame?: { __typename?: 'StartGameResponseType', data?: Array<{ __typename?: 'TableCardType', userId?: string | null, cardId: string, card?: { __typename?: 'CardType', id?: string | null, name?: string | null } | null } | null> | null } | null };

export type StartCounterMutationVariables = Exact<{
  data?: InputMaybe<StartCounterInput>;
}>;


export type StartCounterMutation = { __typename?: 'Mutation', startCounter?: { __typename: 'StartCounterResponse' } | null };

export type PlaceBidMutationVariables = Exact<{
  data?: InputMaybe<PlaceBidInput>;
}>;


export type PlaceBidMutation = { __typename?: 'Mutation', placeBid?: { __typename?: 'PlaceBidResponse', data?: { __typename?: 'BidHistoryType', amount: number, cardId: string, userId: string } | null } | null };

export type CompleteCardAuctionMutationVariables = Exact<{
  data?: InputMaybe<CompleteCardAuctionInputType>;
}>;


export type CompleteCardAuctionMutation = { __typename?: 'Mutation', completeCardAuction?: { __typename?: 'CompleteCardAuctionResponseType', message?: string | null, data?: { __typename?: 'WalletType', userId?: string | null, balance?: number | null } | null } | null };

export type CompletGameMutationVariables = Exact<{
  gameSessionId: Scalars['String']['input'];
}>;


export type CompletGameMutation = { __typename?: 'Mutation', completeGame?: { __typename?: 'CompleteGameResponseType', data?: Array<{ __typename?: 'CompleteGameData', amount?: number | null, points?: number | null, position?: number | null, userId: string } | null> | null } | null };

export type GetTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTablesQuery = { __typename?: 'Query', getTables?: { __typename?: 'GetTablesResponse', data?: Array<{ __typename?: 'TableType', id: string, createdBy?: string | null, isActive?: boolean | null, slug?: string | null, status?: TableStatusEnum | null, gameSessions?: Array<{ __typename?: 'GameSessionType', id?: string | null, gameId?: string | null, status?: GameSessionStatusEnum | null, tableId?: string | null, gameSessionUsers?: Array<{ __typename?: 'GameSessionUsersType', gameSessionId?: string | null, user?: { __typename?: 'UserType', id?: string | null } | null } | null> | null } | null> | null } | null> | null } | null };

export type GetUserWalletQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetUserWalletQuery = { __typename?: 'Query', getUserWallet?: { __typename?: 'AddMoneyResponse', data?: { __typename?: 'WalletType', balance?: number | null, userId?: string | null } | null } | null };

export type GetGameSessionUsersQueryVariables = Exact<{
  gameSessionId: Scalars['String']['input'];
}>;


export type GetGameSessionUsersQuery = { __typename?: 'Query', getGameSessionUsers?: { __typename?: 'GetGameSessionUsersResponseType', data?: Array<{ __typename?: 'GameSessionUsersType', isHost?: boolean | null, gameSessionId?: string | null, user?: { __typename?: 'UserType', id?: string | null, userName?: string | null, wallet?: { __typename?: 'WalletType', balance?: number | null } | null } | null } | null> | null } | null };

export type GetAllCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCardsQuery = { __typename?: 'Query', getAllCards?: { __typename?: 'GetAllCardResponseType', data?: Array<{ __typename?: 'CardType', id?: string | null, name?: string | null, value?: number | null, copies?: number | null } | null> | null } | null };

export type GetAuctionCardQueryVariables = Exact<{
  data?: InputMaybe<GetAuctionCardInputType>;
}>;


export type GetAuctionCardQuery = { __typename?: 'Query', getAuctionCard?: { __typename?: 'GetAuctionCardResponseType', data?: { __typename?: 'TableCardType', id: string, cardId: string, card?: { __typename?: 'CardType', name?: string | null, value?: number | null } | null } | null } | null };

export type GetConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigQuery = { __typename?: 'Query', getConfig?: { __typename?: 'GetConfigResponseType', data?: Array<{ __typename?: 'ConfigType', key?: ConfigKeyEnum | null, value?: { __typename?: 'ConfigValueType', amount?: number | null, default?: number | null, total?: number | null, round1?: number | null, round2?: number | null, round3?: number | null, min?: number | null, max?: number | null, isUniversal?: boolean | null, bidValues?: Array<{ __typename?: 'BidValuesType', amount?: number | null, values?: Array<number | null> | null } | null> | null } | null } | null> | null } | null };

export type GetLastBidQueryVariables = Exact<{
  data?: InputMaybe<GetLastBidInputType>;
}>;


export type GetLastBidQuery = { __typename?: 'Query', getLastBid?: { __typename?: 'PlaceBidResponse', data?: { __typename?: 'BidHistoryType', amount: number, cardId: string } | null } | null };

export type GetGameSessionQueryVariables = Exact<{
  gameSessionId: Scalars['String']['input'];
}>;


export type GetGameSessionQuery = { __typename?: 'Query', getGameSession?: { __typename?: 'GetGameSessionResponseType', data?: { __typename?: 'GameSessionType', potAmount?: number | null } | null } | null };


export const GetUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"getUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetUserInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserMutation, GetUserMutationVariables>;
export const CreatePrivateTableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePrivateTable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPrivateTable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<CreatePrivateTableMutation, CreatePrivateTableMutationVariables>;
export const AddUsersToTableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddUsersToTable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"addUsersToTableData"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddUsersToTableInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addUsersToTable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"addUsersToTableData"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"tableId"}},{"kind":"Field","name":{"kind":"Name","value":"gameSessionUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gameSessionId"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AddUsersToTableMutation, AddUsersToTableMutationVariables>;
export const AddMoneyToWalletDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addMoneyToWallet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AddMoneyInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMoneyToWallet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"balance"}}]}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<AddMoneyToWalletMutation, AddMoneyToWalletMutationVariables>;
export const StartGameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartGame"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"startGameId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startGame"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"startGameId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"card"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"cardId"}}]}}]}}]}}]} as unknown as DocumentNode<StartGameMutation, StartGameMutationVariables>;
export const StartCounterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartCounter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"StartCounterInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startCounter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<StartCounterMutation, StartCounterMutationVariables>;
export const PlaceBidDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PlaceBid"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PlaceBidInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeBid"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"cardId"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]}}]} as unknown as DocumentNode<PlaceBidMutation, PlaceBidMutationVariables>;
export const CompleteCardAuctionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CompleteCardAuction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CompleteCardAuctionInputType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completeCardAuction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"balance"}}]}}]}}]}}]} as unknown as DocumentNode<CompleteCardAuctionMutation, CompleteCardAuctionMutationVariables>;
export const CompletGameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CompletGame"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameSessionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completeGame"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameSessionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameSessionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"points"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]}}]} as unknown as DocumentNode<CompletGameMutation, CompletGameMutationVariables>;
export const GetTablesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}},{"kind":"Field","name":{"kind":"Name","value":"gameSessions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"gameId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"tableId"}},{"kind":"Field","name":{"kind":"Name","value":"gameSessionUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gameSessionId"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<GetTablesQuery, GetTablesQueryVariables>;
export const GetUserWalletDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserWallet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserWallet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"balance"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserWalletQuery, GetUserWalletQueryVariables>;
export const GetGameSessionUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGameSessionUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameSessionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getGameSessionUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameSessionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameSessionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isHost"}},{"kind":"Field","name":{"kind":"Name","value":"gameSessionId"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}},{"kind":"Field","name":{"kind":"Name","value":"wallet"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"balance"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetGameSessionUsersQuery, GetGameSessionUsersQueryVariables>;
export const GetAllCardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAllCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllCards"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"copies"}}]}}]}}]}}]} as unknown as DocumentNode<GetAllCardsQuery, GetAllCardsQueryVariables>;
export const GetAuctionCardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAuctionCard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAuctionCardInputType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAuctionCard"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"cardId"}},{"kind":"Field","name":{"kind":"Name","value":"card"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetAuctionCardQuery, GetAuctionCardQueryVariables>;
export const GetConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"default"}},{"kind":"Field","name":{"kind":"Name","value":"total"}},{"kind":"Field","name":{"kind":"Name","value":"round1"}},{"kind":"Field","name":{"kind":"Name","value":"round2"}},{"kind":"Field","name":{"kind":"Name","value":"round3"}},{"kind":"Field","name":{"kind":"Name","value":"default"}},{"kind":"Field","name":{"kind":"Name","value":"min"}},{"kind":"Field","name":{"kind":"Name","value":"max"}},{"kind":"Field","name":{"kind":"Name","value":"bidValues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"values"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isUniversal"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetConfigQuery, GetConfigQueryVariables>;
export const GetLastBidDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLastBid"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetLastBidInputType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLastBid"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"cardId"}}]}}]}}]}}]} as unknown as DocumentNode<GetLastBidQuery, GetLastBidQueryVariables>;
export const GetGameSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getGameSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gameSessionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getGameSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gameSessionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gameSessionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"potAmount"}}]}}]}}]}}]} as unknown as DocumentNode<GetGameSessionQuery, GetGameSessionQueryVariables>;