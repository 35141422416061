import React, { createContext, useReducer } from 'react';
import { ConfigType, UserType } from './__generated__/graphql';
import client from './apollo';
import api from './common/api';
import {
  AUCTION_STATE_REF,
  CARDS_PER_ROUND,
  RARIO_CONFIGURABLES,
  RARIO_USER_DETAILS,
  REFRESH_TOKEN,
  TABLE_DETAILS,
  TOKEN,
  USER_CARDS,
} from './common/constants';
import {
  AppAction,
  AppActionType,
  AppContextType,
  AppState,
  TableDetailsType,
} from './types/appContext.type';
import {
  AuctionState,
  ChildrenPropType,
  DistributedRounds,
  UserCards,
} from './types/common.type';

const getLoggedInUser = (): UserType | null => {
  const loggedInUser = sessionStorage.getItem(RARIO_USER_DETAILS);
  return loggedInUser ? JSON.parse(loggedInUser) : null;
};

const getTableDetails = (): TableDetailsType | null => {
  const tableDetails = sessionStorage.getItem(TABLE_DETAILS);
  return tableDetails ? JSON.parse(tableDetails) : null;
};

const getSessionCards = (): UserCards | null => {
  const sessioncards = sessionStorage.getItem(USER_CARDS);
  return sessioncards ? JSON.parse(sessioncards) : null;
};

const getConfigurables = (): ConfigType[] | null => {
  const configs = sessionStorage.getItem(RARIO_CONFIGURABLES);
  return configs ? JSON.parse(configs) : null;
};

const getCardsPersRound = (): DistributedRounds | null => {
  const cards = sessionStorage.getItem(CARDS_PER_ROUND);
  return cards ? JSON.parse(cards) : null;
};

const rounds = getConfigurables()?.find(
  (config) => config?.key === 'number_of_rounds',
);
const getAuctionRefData = (): AuctionState => {
  const cards = sessionStorage.getItem(AUCTION_STATE_REF);
  return cards
    ? JSON.parse(cards)
    : {
        players: [],
        currentPlayer: null,
        currentCardIndex: 0,
        currentPlayerIndex: 0,
        rounds: rounds?.value?.total || 3,
        cardsPerRound: [
          rounds?.value?.round1 || 3,
          rounds?.value?.round2 || 2,
          rounds?.value?.round3 || 2,
        ],
        roundIndex: 0,
        highestBid: 0,
        highestBidder: null,
        skippedPlayers: [],
        currentAutionCard: null,
      };
};
const initialState: AppState = {
  currentUser: getLoggedInUser() || {},
  authenticated: !!localStorage.getItem(TOKEN),
  authToken: localStorage.getItem(TOKEN),
  hasGameStarted: getSessionCards() ? true : false,
  userWalletBalance: 0,
  tableDetails: getTableDetails() || null,
  playerCards: getSessionCards() || null,
  appConfigurables: getConfigurables() || null,
  cardsPerRounds: getCardsPersRound() || null,
  auctionRefData: getAuctionRefData(),
};

const reducer = (state: AppState, action: AppAction) => {
  switch (action?.type) {
    case AppActionType.setCurrentUser:
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      sessionStorage.setItem(
        RARIO_USER_DETAILS,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : '',
      );
      return { ...state, currentUser: { ...user } };
    case AppActionType.logout:
      delete api?.defaults?.headers?.common?.Authorization;
      sessionStorage.clear();
      client?.clearStore();
      return {
        ...initialState,
      };
    case AppActionType.gameStarted:
      return { ...state, hasGameStarted: action.data };
    case AppActionType.setUserWallet:
      return { ...state, userWalletBalance: action.data };
    case AppActionType.setConfig:
      sessionStorage.setItem(RARIO_CONFIGURABLES, JSON.stringify(action.data));
      return { ...state, appConfigurables: action.data };

    case AppActionType.setTableDetails:
      // eslint-disable-next-line no-case-declarations
      const tableData = action.data || {};
      sessionStorage.setItem(
        TABLE_DETAILS,
        tableData && Object?.keys(tableData)?.length
          ? JSON?.stringify(tableData)
          : '',
      );
      return { ...state, tableDetails: action.data };
    case AppActionType.setUserCards:
      // eslint-disable-next-line no-case-declarations
      const cardsData = action.data || {};
      sessionStorage.setItem(
        USER_CARDS,
        cardsData && Object?.keys(cardsData)?.length
          ? JSON?.stringify(cardsData)
          : '',
      );
      return { ...state, playerCards: action.data };

    case AppActionType.setCardsPerRound:
      // eslint-disable-next-line no-case-declarations
      const roundCardsData = action.data || {};
      sessionStorage.setItem(
        CARDS_PER_ROUND,
        roundCardsData && Object?.keys(roundCardsData)?.length
          ? JSON?.stringify(roundCardsData)
          : '',
      );
      return { ...state, cardsPerRounds: action.data };

    case AppActionType.setAuctionRefData:
      // eslint-disable-next-line no-case-declarations
      const refData = action.data || {};
      sessionStorage.setItem(
        AUCTION_STATE_REF,
        refData && Object?.keys(refData)?.length
          ? JSON?.stringify(refData)
          : '',
      );
      return { ...state, auctionRefData: action.data };

    default:
      return { ...state };
  }
};

const AppContext = createContext<AppContextType | null>(null);

const AppContextProvider: React.FC<ChildrenPropType> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => localStorage.getItem(TOKEN) || null;
  const getUserSession = () =>
    sessionStorage.getItem(RARIO_USER_DETAILS) || null;
  const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    sessionStorage?.getItem(RARIO_USER_DETAILS)
      ? JSON?.parse(sessionStorage.getItem(RARIO_USER_DETAILS) ?? '')
      : {};

  const isAuthenticated = () => state.authenticated;

  const initializeAuth = (
    authToken: string | null,
    userData: UserType | null,
    refreshToken?: string | null,
  ) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: AppActionType.setAuthToken, data: token });
      dispatch({ type: AppActionType.setRefreshToken, data: refresh });
      dispatch({ type: AppActionType.setAuthenticated, data: true });
      dispatch({ type: AppActionType.setCurrentUser, data: user });
    }
  };

  const value: AppContextType = {
    state,
    dispatch,
    isAuthenticated,
    getUserSession,
    getRefreshToken,
    initializeAuth,
    getCurrentUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
