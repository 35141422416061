/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  AUTHENTICATION: '/authentication',
  GAMES: 'games',
  UPLOAD_CSV: 'upload-player-csv',
  PRIVATE: 'private',
  PLAY_GAME: ':tableId',
  GAME_CONCLUSION: 'conclusion',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  MOVIES: 'Movies',
  SAMPLE: 'Sample',
};

export const PUSHER_CHANNELS = {
  PRESENCE_TABLE: 'presence-table',
  GAME_SESSION: 'presence-game-session',
  AUCTION_BID: 'presence-auction-bid',
  GAME_POT: 'presence-game-pot',
  USER_WALLET: 'presence-user-wallet',
  GAME_SESSION_ENDED: 'presence-game-session-completed',
};

export const PUSHER_EVENTS = {
  USER_COUNT: 'user-count',
  START_GAME: 'start-game',
  GET_AUCTION_CARD: 'get-auction-card',
  TIMER: 'timer',
  BID_PLACED: 'bid-placed',
  GAME_COMPLETED: 'game-completed',
  CARD_PURCHASED: 'card-purchased',
  CARD_AUCTION_COMPLETE: 'card-auction-complete',
  POT_MONEY_UPDATED: 'pot-money-updated',
  GET_CURRENT_USER: 'client-current-player',
  WALLET_UPDATED: 'wallet-updated',

  // start-card-auction - get round details and auction card
  //
};
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 32, lg: 32 };

export const COMPANY_INFO = { COOKIE_EXPIRY: 259200 };

export const RARIO_USER_DETAILS = 'RARIO_USER_DETAILS';
export const TABLE_DETAILS = 'TABLE_DETAILS';
export const USER_CARDS = 'USER_CARDS';
export const CARDS_PER_ROUND = 'CARDS_PER_ROUND';
export const CALLBACK_URL = 'CALLBACK_URL';
export const RARIO_CONFIGURABLES = 'RARIO_CONFIGURABLES';
export const AUCTION_STATE_REF = 'AUCTION_STATE_REF';
export const MOBILE_DEVICE_BREAKPOINT = 1024;
