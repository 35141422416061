import { DownOutlined, LoginOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import { AppContextType } from '../../../types/appContext.type';

const UserProfile = () => {
  const {
    location: { pathname },
  } = useRouter();

  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const items = [
    {
      key: 'logout',
      label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
      icon: <LoginOutlined />,
    },
  ];

  return (
    <div>
      <Dropdown
        menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
        trigger={['click']}
      >
        <Space className="pointer d-flex align-center gap-4" size="small">
          <Avatar alt={currentUser?.userName || ''}>
            {currentUser?.userName?.[0] ?? ''}
          </Avatar>
          <span className="m-hide">{currentUser?.userName ?? ''}</span>
          <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );
};

export default UserProfile;
