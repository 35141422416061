/* eslint-disable no-console */
import Pusher, { UserAuthenticationOptions } from 'pusher-js';
import { UserType } from './__generated__/graphql';
import { RARIO_USER_DETAILS } from './common/constants';

// Configure your Pusher credentials

const getLoggedInUser = (): UserType | null => {
  const loggedInUser = sessionStorage.getItem(RARIO_USER_DETAILS);
  return loggedInUser ? JSON.parse(loggedInUser) : null;
};

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || '',
  userAuthentication: {
    endpoint: `${process.env.REACT_APP_REST_URL}/api/v1/pusher/user/auth`,
    headers: { 'x-api-user-id': getLoggedInUser()?.id },
  } as UserAuthenticationOptions,
  channelAuthorization: {
    endpoint: `${process.env.REACT_APP_REST_URL}/api/v1/pusher/channel/auth`,
    transport: 'ajax',
    headers: { 'x-api-user-id': getLoggedInUser()?.id },
  },
});

export default pusher;
