import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { AppContextType } from '../../types/appContext.type';
import GameScreen from './GameScreen';
import WaitingScreen from './WaitingScreen';

const PlayGame = () => {
  const {
    state: { hasGameStarted },
  } = useContext(AppContext) as AppContextType;

  return <>{!hasGameStarted ? <WaitingScreen /> : <GameScreen />}</>;
};

export default PlayGame;
