import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Spin, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { TableStatusEnum } from '../../__generated__/graphql';
import { GUTTER_VARIATIONS, ROUTES } from '../../common/constants';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { CREATE_PRIVATE_TABLE } from '../auth/graphql/mutations';
import './GamesList.less';
import PrivateLinkModal from './PrivateLinkModal';
import TableCard from './TableCard';
import { GET_GAME_TABLES } from './graphql/queries';
const GamesList = () => {
  const {
    state: { currentUser, tableDetails, appConfigurables },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [privateLink, setPrivateLink] = useState('');
  const { data: gameTablesData, loading } = useQuery(GET_GAME_TABLES, {
    fetchPolicy: 'network-only',
  });

  const [privateTableMutate, { loading: privateTableloading }] = useMutation(
    CREATE_PRIVATE_TABLE,
    {
      onError() {}, // Always write this method for error handling in all mutation.
    },
  );

  const rounds = appConfigurables?.find(
    (config) => config?.key === 'number_of_rounds',
  );

  useEffect(() => {
    dispatch({ type: AppActionType.gameStarted, data: false });
    dispatch({
      type: AppActionType.setCardsPerRound,
      data: null,
    });

    dispatch({ type: AppActionType.gameStarted, data: false });

    dispatch({
      type: AppActionType.setAuctionRefData,
      data: {
        players: [],
        currentPlayer: null,
        currentCardIndex: 0,
        currentPlayerIndex: 0,
        rounds: rounds?.value?.total || 3,
        cardsPerRound: [
          rounds?.value?.round1 || 3,
          rounds?.value?.round2 || 2,
          rounds?.value?.round3 || 2,
        ],
        roundIndex: 0,
        highestBid: 0,
        highestBidder: null,
        skippedPlayers: [],
        currentAutionCard: null,
      },
    });
    dispatch({ type: AppActionType.setUserCards, data: null });
    dispatch({
      type: AppActionType.setTableDetails,
      data: {},
    });
  }, []);

  const createPrivateLink = () => {
    if (currentUser?.id) {
      privateTableMutate({
        variables: { userId: currentUser?.id },
        onCompleted: (response) => {
          if (response?.createPrivateTable?.data) {
            setPrivateLink(response?.createPrivateTable?.data?.id);
            setIsModalOpen(true);
          }
        },
      });
    } else {
      message.error('Please Login first');
    }
  };
  const handleJoinTable = async () => {
    dispatch({
      type: AppActionType.setTableDetails,
      data: {
        ...tableDetails,
        gameSessionId: tableDetails?.gameSessionId,
      },
    });

    try {
      await navigator.clipboard.writeText(
        `${window.location.href}${ROUTES.PRIVATE}/${privateLink}`,
      );
      message.success('Link copied to clipboard');
    } catch (error) {
      message.error('Failed to copy the link');
    }

    window.location.href = `${window.location.href}${ROUTES.PRIVATE}/${privateLink}`;

    message.success('Link copied to clipboard');
    setIsModalOpen(false);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.href}${ROUTES.PRIVATE}/${privateLink}`,
    );
    message.success('Link copied to clipboard');
    setIsModalOpen(false);
  };

  return (
    <Spin spinning={loading}>
      <div className="page-header">
        <div className="page-header-left">
          <span>Table list</span>
          <h2>Active Tables</h2>
        </div>
        <div className="page-header-right">
          {!loading && (
            <Button
              size="small"
              type="primary"
              className="private-link-button"
              loading={privateTableloading}
              onClick={createPrivateLink}
            >
              + Create Private link
            </Button>
          )}
        </div>
      </div>

      <div className="tables-list-wrap">
        <div className="site-statistic-demo-card home-card">
          <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
            {gameTablesData?.getTables?.data?.map((tableData, index) => {
              return (
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  key={tableData?.id}
                >
                  <TableCard
                    index={index || 0}
                    tableId={tableData?.id || ''}
                    tableSlug={tableData?.slug || ''}
                    sessionUserCount={
                      tableData?.gameSessions?.[0]?.gameSessionUsers?.length ||
                      0
                    }
                    onGoingGame={tableData?.status === TableStatusEnum?.Ongoing}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <PrivateLinkModal
        handleJoinTable={handleJoinTable}
        handleCopyLink={handleCopyLink}
        isModalOpen={isModalOpen}
        linkText={`${window.location.href}${ROUTES.PRIVATE}/${privateLink}`}
        setIsModalOpen={setIsModalOpen}
      />
    </Spin>
  );
};

export default GamesList;
