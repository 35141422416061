/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { CardType } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import UserAvatar from '../../assets/images/user-avatar.jpg';

import { AppContextType } from '../../types/appContext.type';
import {
  AuctionState,
  CurrentTimerType,
  PlayerType,
} from '../../types/common.type';
import ProgressBar from './ProgressBar';
type PlayerProps = {
  player: PlayerType;
  index: number;
  totalPlayers: number;
  currentPlayerTime?: CurrentTimerType;
  currentPlayerData?: PlayerType;
  currentPusherUser?: AuctionState;
  allCards?: (CardType | null)[] | null;
  isUniversalFlow?: boolean | null;
  userWalletRef?: any;
  highestBidder: any;
  highestBid: any;
};

const Player: React.FC<PlayerProps> = ({
  player,
  index,
  totalPlayers,
  currentPusherUser,
  allCards,
  isUniversalFlow,
  highestBidder,
}) => {
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const angle = (index / totalPlayers) * 2 * Math.PI + Math.PI / 2; // Adjust starting angle
  const x = 50 + 45 * Math.cos(angle); // X-coordinate
  const y = 50 + 45 * Math.sin(angle); // Y-coordinate
  const positionClass =
    x < 50 && Math.abs(x - 50) > Math.abs(y - 50)
      ? 'player-left'
      : x > 50 && Math.abs(x - 50) > Math.abs(y - 50)
        ? 'player-right'
        : y < 50
          ? 'player-top'
          : 'player-bottom';
  const style: React.CSSProperties = {
    position: 'absolute',
    top: `${y}%`,
    left: `${x}%`,
    transform: `translate(-50%, -50%)`,
  };

  const [sum, setSum] = useState(0);

  useEffect(() => {
    let sum2 = 0;

    const startIndex = player?.userId === currentUser?.id ? 0 : 2;

    player?.cards?.slice(startIndex)?.forEach((card) => {
      const cardValue =
        allCards?.find((playerCard) => playerCard?.id === card)?.value || 0;
      sum2 += cardValue;
    });

    // Set the sum in state
    setSum(sum2);
  }, [player]);

  return (
    <div className={`player active ${positionClass}`} style={style}>
      {highestBidder?.userId === player?.userId && (
        <div className="pulsating-circle" />
      )}
      <div className="player-img">
        <img src={UserAvatar} alt={`Player ${index + 1}`} className="avatar" />
        {(!isUniversalFlow
          ? currentPusherUser?.currentPlayer?.userId === player?.userId
          : false) && <ProgressBar percentage={100} />}
        <div className="points">{sum}</div>
      </div>
      <div className="player-info">
        <div className="player-name">
          <span title={player?.userName}>{player?.userName}</span>
        </div>
        <div className="player-money">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="#fff"
            viewBox="0 0 256 256"
          >
            <path d="M216,64H56a8,8,0,0,1,0-16H192a8,8,0,0,0,0-16H56A24,24,0,0,0,32,56V184a24,24,0,0,0,24,24H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64Zm0,128H56a8,8,0,0,1-8-8V78.63A23.84,23.84,0,0,0,56,80H216Zm-48-60a12,12,0,1,1,12,12A12,12,0,0,1,168,132Z"></path>
          </svg>
          {player?.walletBalance}
        </div>
      </div>
      <div className="player-card-list">
        <Avatar.Group maxCount={2}>
          {player.cards
            ?.slice(player?.userId === currentUser?.id ? 0 : 2)
            ?.map((card, cardIndex) => (
              <Tooltip
                key={cardIndex}
                title={
                  <>
                    <div>
                      {allCards?.find((playerCard) => playerCard?.id === card)
                        ?.name || 'card name'}
                    </div>
                    <div>
                      Pts.{' '}
                      {allCards?.find((playerCard) => playerCard?.id === card)
                        ?.value || 'card points'}
                    </div>
                  </>
                }
                placement="top"
              >
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              </Tooltip>
            ))}
        </Avatar.Group>
      </div>
    </div>
  );
};

export default Player;
