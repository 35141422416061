import { List } from 'antd';
import { CardType } from '../../__generated__/graphql';
const DeckOfCards = ({
  deckLoading,
  allCards,
}: {
  deckLoading: boolean;
  allCards: (CardType | null)[] | null | undefined;
}) => {
  return allCards ? (
    <div className="list-of-payer-drawer">
      <List
        loading={deckLoading}
        dataSource={allCards}
        renderItem={(item) => (
          <List.Item key={item?.id}>
            <List.Item.Meta
              title={
                <div className="d-flex align-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className="mr-4"
                    fill="var(--primary)"
                    viewBox="0 0 256 256"
                  >
                    <path d="M176,112H152a8,8,0,0,1,0-16h24a8,8,0,0,1,0,16ZM104,96H96V88a8,8,0,0,0-16,0v8H72a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16ZM241.48,200.65a36,36,0,0,1-54.94,4.81c-.12-.12-.24-.24-.35-.37L146.48,160h-37L69.81,205.09l-.35.37A36.08,36.08,0,0,1,44,216,36,36,0,0,1,8.56,173.75a.68.68,0,0,1,0-.14L24.93,89.52A59.88,59.88,0,0,1,83.89,40H172a60.08,60.08,0,0,1,59,49.25c0,.06,0,.12,0,.18l16.37,84.17a.68.68,0,0,1,0,.14A35.74,35.74,0,0,1,241.48,200.65ZM172,144a44,44,0,0,0,0-88H83.89A43.9,43.9,0,0,0,40.68,92.37l0,.13L24.3,176.59A20,20,0,0,0,58,194.3l41.92-47.59a8,8,0,0,1,6-2.71Zm59.7,32.59-8.74-45A60,60,0,0,1,172,160h-4.2L198,194.31a20.09,20.09,0,0,0,17.46,5.39,20,20,0,0,0,16.23-23.11Z"></path>
                  </svg>
                  {item?.name}
                </div>
              }
              description={`Pts. ${item?.value}`}
            />
          </List.Item>
        )}
      />
    </div>
  ) : (
    <div> No data to show</div>
  );
};

export default DeckOfCards;
