/* eslint-disable no-console */
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload, UploadProps } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import axios from 'axios';

const UploadPlayerCsv = () => {
  const props: UploadProps = {
    name: 'file',
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    headers: {
      authorization: 'authorization-text',
    },
    accept: '.csv',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function downloadCSV(data: any, filename = 'user-wallet.csv') {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    // Set the href attribute of the link to the object URL
    link.setAttribute('href', url);
    link.setAttribute('download', filename);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
  const uploadFile = ({ fileList }: UploadChangeParam) => {
    const formData = new FormData();
    const { originFileObj: currentFile } = fileList[0];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData.append('file', currentFile as any);

    axios
      ?.post(
        `${process.env.REACT_APP_REST_URL}/api/v1/import/user-wallets`,
        formData,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
        },
      )
      .then((response) => {
        if (response?.data) {
          message.success(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error('Error in updating wallet');
        downloadCSV(error.response?.data);
      });
  };
  return (
    <div className="upload-wrapper">
      <Upload
        {...props}
        onChange={uploadFile}
        maxCount={1}
        beforeUpload={() => {
          return false;
        }}
      >
        <Button icon={<UploadOutlined />} type="primary">
          Click to Upload
        </Button>
      </Upload>
    </div>
  );
};

export default UploadPlayerCsv;
