import React, { useEffect, useRef } from 'react';

type ProgressBarProps = {
  percentage: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  const radius = 51;
  const circumference = 2 * Math.PI * radius;
  const circleRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    // Ensure the percentage is between 0 and 100
    const validPercentage = Math.max(0, Math.min(percentage, 100));
    const progress = validPercentage / 100;

    const offset = circumference - progress * circumference;

    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset = offset.toString();
    }
  }, [percentage, circumference]);

  return (
    <svg width={120} height={120} viewBox="0 0 120 120">
      <circle
        cx="60"
        cy="60"
        r={radius}
        stroke="#d3d3d3"
        strokeWidth="8"
        fill="none"
      />
      <circle
        ref={circleRef}
        cx="60"
        cy="60"
        r={radius}
        stroke="var(--primary)"
        strokeWidth="8"
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={circumference}
        transform="rotate(-90 60 60)"
      />
    </svg>
  );
};

export default ProgressBar;
