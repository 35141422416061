import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Drawer, Segmented, notification } from 'antd';
import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useWindowSize } from 'react-use';
import { AppContext } from '../../AppContext';
import {
  CardType,
  CompleteGameData,
  GameSessionUsersType,
  TableCardType,
  WalletHistoryTypeEnum,
  WalletType,
} from '../../__generated__/graphql';
import BidAvatar from '../../assets/images/bid.png';
import PotIcon from '../../assets/images/pot-icon.png';
import { PUSHER_CHANNELS, PUSHER_EVENTS, ROUTES } from '../../common/constants';
import { getBidValues, isMobileBrowser } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import pusher from '../../pusher';
import { AppActionType, AppContextType } from '../../types/appContext.type';

import {
  AuctionBid,
  AuctionState,
  CardTypes,
  CurrentTimerType,
  PlayerType,
} from '../../types/common.type';
import {
  ADD_MONEY_TO_WALLET,
  COMPLETE_CARD_AUCTION,
  COMPLETE_GAME,
  PLACE_BID,
  START_COUNTER,
} from '../auth/graphql/mutations';
import DeckOfCards from './DeckOfCards';
import Player from './Player';
import {
  GET_ALL_CARDS,
  GET_AUCTION_CARD,
  GET_GAME_SESSION,
  GET_LAST_BID,
  GET_SESSION_USERS,
  GET_USER_WALLET,
} from './graphql/queries';

let auctionCardId: unknown;
const GameScreen = () => {
  const {
    state: {
      playerCards,
      userWalletBalance,
      currentUser,
      tableDetails,
      appConfigurables,
      cardsPerRounds,
      auctionRefData,
    },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const rounds = appConfigurables?.find(
    (config) => config?.key === 'number_of_rounds',
  );

  const isUniversalFlow =
    appConfigurables?.find((config) => config?.key === 'game_variation')?.value
      ?.isUniversal || false;

  // const isUniversalFlow = false;
  const initialWalletBalance =
    appConfigurables?.find((config) => config?.key === 'top_up_wallet_balance')
      ?.value?.amount || 0;

  const [api, contextHolder] = notification.useNotification({
    stack: false,
  });

  const openNotification = (message: string) => {
    api.open({
      message: message,
      duration: 3,
      className: 'common-notification',
    });
  };

  const [open, setOpen] = useState(false);
  const showDeckOfCards = () => {
    setOpen(true);
  };

  const auctionStateRef = useRef<AuctionState>(auctionRefData);
  const [addTowalletMutate] = useMutation(ADD_MONEY_TO_WALLET, {
    onError: () => {},
  });

  const handleAddWalletBalance = () => {
    addTowalletMutate({
      variables: {
        data: {
          amount: initialWalletBalance || 500,
          userId: currentUser?.id || '',
          type: WalletHistoryTypeEnum?.GameSession,
          gameSessionId: tableDetails?.gameSessionId,
        },
      },
      onCompleted: (response) => {
        if (response?.addMoneyToWallet?.data) {
          dispatch({
            type: AppActionType.setUserWallet,
            data:
              Math.floor(response?.addMoneyToWallet?.data?.balance || 0) || 0,
          });
        }
      },
    });
  };

  const [playersData, setPlayersData] = useState<PlayerType[]>([]);
  const [highestBid, setHighestBid] = useState<number>(0);
  const [currentPusherUser, setCurrentPusherUser] = useState<AuctionState>();
  const [bidTimer, setBidTimer] = useState<number | null>(null);
  const [potAmount, setPotAmount] = useState<number>(0);
  const { navigate } = useRouter();
  const [sessionUsers, setSessionUsers] =
    useState<(GameSessionUsersType | null)[]>();
  const { width } = useWindowSize();
  const [allCards, setAllCards] = useState<
    (CardType | null)[] | null | undefined
  >([]);
  const [auctionCard, setAuctionCard] = useState<CardTypes>();
  const userChannel = pusher.subscribe(
    `${PUSHER_CHANNELS.GAME_SESSION}-${tableDetails?.tableSlug}`,
  );
  const [completeCardAuction] = useMutation(COMPLETE_CARD_AUCTION, {
    onError: () => {},
  });

  const [completeGame] = useMutation(COMPLETE_GAME, {
    onError: () => {},
  });

  const [getUserWallet] = useLazyQuery(GET_USER_WALLET, {
    fetchPolicy: 'network-only',
  });

  const [getGameSession] = useLazyQuery(GET_GAME_SESSION, {
    fetchPolicy: 'network-only',
  });

  const [getSessionUsers] = useLazyQuery(GET_SESSION_USERS, {
    fetchPolicy: 'network-only',
    variables: { gameSessionId: tableDetails?.gameSessionId || '' },
    onCompleted: ({ getGameSessionUsers }) => {
      if (getGameSessionUsers?.data) {
        setSessionUsers(getGameSessionUsers?.data);

        const playerState: PlayerType[] = getGameSessionUsers?.data?.reduce(
          (acc: PlayerType[], player) => {
            if (player?.user?.id) {
              acc.push({
                userName: player?.user?.userName || '',
                cards: playerCards?.[player?.user?.id] || [],
                hasPassed: false,
                userId: player?.user?.id,
                isHost: player?.isHost || false,
                walletBalance: Math.floor(player?.user?.wallet?.balance || 0),
              });
            }
            return acc;
          },
          [] as PlayerType[],
        );
        setPlayersData(playerState);
        auctionStateRef.current.players = playerState;
      }
    },
  });

  const [getAuctionCard] = useLazyQuery(GET_AUCTION_CARD, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });
  const getCardToBeAuctioned = async () => {
    if (tableDetails?.tableSlug && tableDetails?.gameSessionId) {
      await getAuctionCard({
        variables: {
          data: {
            slug: tableDetails?.tableSlug,
            gameSessionId: tableDetails?.gameSessionId,
          },
        },
        onCompleted: () => {},
      });
    }
  };

  const [getLastBid] = useLazyQuery(GET_LAST_BID, {
    fetchPolicy: 'network-only',
  });

  const [startTimerMutate] = useMutation(START_COUNTER, {
    onError() {}, // Always write this method for error handling in all mutation.
  });
  const [placeBid] = useMutation(PLACE_BID, {
    onError: () => {},
  });

  const moveCurrentPlayer = () => {
    if (
      auctionStateRef.current.currentPlayerIndex ===
      auctionStateRef.current.players?.length - 1
    ) {
      auctionStateRef.current.currentPlayerIndex = 0;
    } else {
      auctionStateRef.current.currentPlayerIndex += 1;
    }

    auctionStateRef.current.currentPlayer =
      auctionStateRef.current.players[
        auctionStateRef.current.currentPlayerIndex
      ];

    userChannel.trigger(
      PUSHER_EVENTS.GET_CURRENT_USER,
      auctionStateRef?.current,
    );
    setCurrentPusherUser({ ...auctionStateRef.current });
    startTimerMutate({
      variables: {
        data: {
          cardId:
            (auctionStateRef?.current?.currentAutionCard?.id as string) ||
            (auctionCardId as string),
          gameSessionId: tableDetails?.gameSessionId as string,
        },
      },
      onCompleted: () => {},
    });
  };
  const [bidValue, setBidValue] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBid = async (amount: any) => {
    placeBid({
      variables: {
        data: {
          amount: amount + highestBid,
          cardId:
            (auctionStateRef.current.currentAutionCard?.id as string) ||
            (auctionCard?.id as string),

          userId: isUniversalFlow
            ? (currentUser?.id as string)
            : (auctionStateRef?.current?.currentPlayer?.userId as string),
          gameSessionId: tableDetails?.gameSessionId as string,
        },
      },
      onCompleted: (response) => {
        const highestBidder = playersData?.find(
          (player) => player?.userId === response?.placeBid?.data?.userId,
        );
        auctionStateRef.current.highestBid = response?.placeBid?.data
          ?.amount as number;
        auctionStateRef.current.highestBidder = highestBidder || null;
        if (isUniversalFlow) {
          startTimerMutate({
            variables: {
              data: {
                cardId: auctionCardId as string,
                gameSessionId: tableDetails?.gameSessionId as string,
              },
            },
            onCompleted: () => {},
          });
        } else {
          if (
            auctionStateRef.current?.skippedPlayers.length ===
              auctionStateRef.current?.players?.length - 1 &&
            response?.placeBid?.data?.userId
          ) {
            // eslint-disable-next-line no-use-before-define
            handleCompleteCard(response?.placeBid?.data?.userId);
          } else {
            moveCurrentPlayer();
          }
        }
      },
    });
  };

  useEffect(() => {
    const auctionCardChannel = pusher.subscribe(
      `${PUSHER_CHANNELS.GAME_SESSION}-${tableDetails?.tableSlug}`,
    );

    auctionCardChannel.bind(
      PUSHER_EVENTS.GET_AUCTION_CARD,
      function ({ data }: { data: TableCardType }) {
        if (tableDetails?.gameSessionId && data?.card?.id) {
          getLastBid({
            variables: {
              data: {
                gameSessionId: tableDetails?.gameSessionId,
                cardId: data?.card?.id,
              },
            },
            onCompleted: (response) => {
              if (response?.getLastBid?.data?.amount) {
                auctionStateRef.current.highestBid = response?.getLastBid?.data
                  ?.amount as number;
                setHighestBid(response?.getLastBid?.data?.amount || 0);
              }
            },
            onError: () => {
              setHighestBid(data?.card?.value || 0);
            },
          });
        }
        setAuctionCard({
          tableCardId: data?.id,
          id: data?.card?.id,
          name: data?.card?.name,
          value: data?.card?.value,
        });

        auctionStateRef.current.currentAutionCard = {
          tableCardId: data?.id,
          id: data?.card?.id,
          name: data?.card?.name,
          value: data?.card?.value,
        };
        auctionCardId = data?.card?.id;
      },
    );

    userChannel.bind(
      PUSHER_EVENTS.GET_CURRENT_USER,
      function (data: AuctionState) {
        dispatch({
          type: AppActionType.setAuctionRefData,
          data: data,
        });
        setCurrentPusherUser({ ...data });
        auctionStateRef.current = data;
        if (auctionStateRef.current?.currentPlayer === null) {
          const currentPlayer =
            auctionStateRef.current.players[
              auctionStateRef.current.currentPlayerIndex
            ];
          auctionStateRef.current.currentPlayer = currentPlayer;
          setCurrentPusherUser({ ...auctionStateRef.current });

          startTimerMutate({
            variables: {
              data: {
                cardId:
                  (auctionCardId as string) || (auctionCard?.id as string),
                gameSessionId: tableDetails?.gameSessionId as string,
              },
            },
            onCompleted: () => {},
          });
        }
      },
    );

    const bidChannel = pusher.subscribe(
      `${PUSHER_CHANNELS.AUCTION_BID}-${tableDetails?.gameSessionId}`,
    );

    bidChannel.bind(
      PUSHER_EVENTS.BID_PLACED,
      function ({ data }: { data: AuctionBid }) {
        const highestBidder = auctionStateRef.current?.players?.find(
          (player) => player?.userId === data?.userId,
        );

        auctionStateRef.current.highestBid = data?.amount;
        auctionStateRef.current.highestBidder = highestBidder || null;
        setHighestBid(data?.amount);
      },
    );

    bidChannel.bind(
      PUSHER_EVENTS.CARD_AUCTION_COMPLETE,
      function (data: { message: string }) {
        openNotification(data?.message);
      },
    );

    const gameCompleteChannel = pusher.subscribe(
      `${PUSHER_CHANNELS.GAME_SESSION_ENDED}-${tableDetails?.gameSessionId}`,
    );

    gameCompleteChannel.bind(
      PUSHER_EVENTS.GAME_COMPLETED,
      function (data: { data: CompleteGameData[] }) {
        dispatch({ type: AppActionType.gameStarted, data: false });
        dispatch({ type: AppActionType.setUserCards, data: null });
        dispatch({
          type: AppActionType.setAuctionRefData,
          data: {
            players: [],
            currentPlayer: null,
            currentCardIndex: 0,
            currentPlayerIndex: 0,
            rounds: rounds?.value?.total || 3,
            cardsPerRound: [
              rounds?.value?.round1 || 3,
              rounds?.value?.round2 || 2,
              rounds?.value?.round3 || 2,
            ],
            roundIndex: 0,
            highestBid: 0,
            highestBidder: null,
            skippedPlayers: [],
            currentAutionCard: null,
          },
        });

        navigate(ROUTES.GAME_CONCLUSION, {
          state: data,
        });
      },
    );

    const gamePotChannel = pusher.subscribe(
      `${PUSHER_CHANNELS.GAME_POT}-${tableDetails?.gameSessionId}`,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gamePotChannel.bind(PUSHER_EVENTS.POT_MONEY_UPDATED, function (data: any) {
      setPotAmount(data?.potAmount);
    });

    const userWalletChannel = pusher.subscribe(
      `${PUSHER_CHANNELS.USER_WALLET}-${tableDetails?.gameSessionId}`,
    );

    userWalletChannel.bind(
      PUSHER_EVENTS.WALLET_UPDATED,
      function ({ data }: { data: WalletType }) {
        const updatedPlayers = auctionStateRef.current.players.map(
          (player: PlayerType) => {
            if (player.userId === data?.userId) {
              return {
                ...player,
                walletBalance: Math.floor(data?.balance || 0),
              };
            }
            return player;
          },
        );
        auctionStateRef.current.players = updatedPlayers as PlayerType[];

        if (data?.userId === currentUser?.id) {
          dispatch({
            type: AppActionType.setUserWallet,
            data: Math.floor(data?.balance || 0) || 0,
          });
        }
      },
    );

    return () => {
      auctionCardChannel.unbind_all();
      auctionCardChannel.unsubscribe();
      bidChannel.unbind_all();
      bidChannel.unsubscribe();
      gameCompleteChannel.unbind_all();
      gameCompleteChannel.unsubscribe();
      gamePotChannel.unbind_all();
      gamePotChannel.unsubscribe();
      userWalletChannel.unbind_all();
      userWalletChannel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const timerChannel = pusher.subscribe(
      `${PUSHER_CHANNELS.GAME_SESSION}-${tableDetails?.gameSessionId}`,
    );
    timerChannel.bind(`timer`, function ({ data }: { data: CurrentTimerType }) {
      const isHost = auctionStateRef.current.players.some(
        (player) => player?.userId === currentUser?.id && player?.isHost,
      );

      setBidTimer(() => data?.time);
      if (data?.time === 0) {
        if (isUniversalFlow) {
          if (isHost) {
            // eslint-disable-next-line no-use-before-define
            handleCompleteCard(
              auctionStateRef?.current?.highestBidder?.userId as string,
            );
          }
        } else {
          if (isHost) {
            // eslint-disable-next-line no-use-before-define
            handlePass();
          }
        }
      }
    });

    return () => {
      timerChannel.unbind_all();
      timerChannel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (sessionUsers) getCardToBeAuctioned();
  }, [sessionUsers]);

  const [getAllCards, { loading: deckLoading }] = useLazyQuery(GET_ALL_CARDS, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    getUserWallet({
      variables: { userId: currentUser?.id as string },
      onCompleted: (response) => {
        if (response?.getUserWallet?.data?.balance) {
          dispatch({
            type: AppActionType.setUserWallet,
            data: Math.floor(response?.getUserWallet?.data?.balance || 0),
          });
        }
      },
    });
    getGameSession({
      variables: { gameSessionId: tableDetails?.gameSessionId as string },
      onCompleted: (response) => {
        if (response?.getGameSession?.data) {
          setPotAmount(response?.getGameSession?.data?.potAmount || 0);
        }
      },
    });
  }, []);

  useEffect(() => {
    getAllCards({
      onCompleted: (response) => {
        if (response?.getAllCards?.data) {
          setAllCards(response?.getAllCards?.data);
        }
      },
    });

    getSessionUsers();
  }, []);

  const startAuction = () => {
    userChannel.trigger(
      PUSHER_EVENTS.GET_CURRENT_USER,
      auctionStateRef.current,
    );

    setCurrentPusherUser({ ...auctionStateRef.current });
  };

  useEffect(() => {
    if (auctionCard) {
      startAuction();
    }
  }, [auctionCard]);

  const handleCompleteGame = () => {
    completeGame({
      variables: {
        gameSessionId: tableDetails?.gameSessionId as string,
      },
      onCompleted: () => {},
    });
  };

  const handleCompleteCard = (userId?: string) => {
    completeCardAuction({
      variables: {
        data: {
          amount: auctionStateRef.current.highestBid,
          gameSessionId: tableDetails?.gameSessionId as string,
          cardId:
            (auctionStateRef.current.currentAutionCard?.id as string) ||
            (auctionCard?.id as string),
          userId,

          tableCardId:
            (auctionStateRef.current.currentAutionCard
              ?.tableCardId as string) || (auctionCard?.tableCardId as string),
        },
      },
      onCompleted: (response) => {
        const currentRoundCards =
          auctionStateRef.current.cardsPerRound[
            auctionStateRef.current.roundIndex
          ];

        const wallet = response?.completeCardAuction?.data;
        const updatedPlayers = auctionStateRef.current.players.map(
          (player: PlayerType) => {
            if (
              player.userId === auctionStateRef.current.highestBidder?.userId
            ) {
              return {
                ...player,
                cards: [
                  ...player.cards,
                  auctionStateRef?.current?.currentAutionCard?.id,
                ],
                ...(wallet
                  ? { walletBalance: Math.floor(wallet?.balance || 0) }
                  : {}),
              };
            }
            return player;
          },
        );
        if (wallet?.userId === currentUser?.id) {
          dispatch({
            type: AppActionType.setUserWallet,
            data: Math.floor(wallet?.balance || 0),
          });
        }

        auctionStateRef.current.players = updatedPlayers as PlayerType[];
        auctionStateRef.current.highestBidder = null;
        auctionStateRef.current.skippedPlayers = [];
        if (
          auctionStateRef.current.roundIndex >=
          auctionStateRef.current.rounds - 1
        ) {
          if (
            auctionStateRef.current.currentCardIndex >=
            currentRoundCards - 1
          ) {
            handleCompleteGame();
          } else {
            auctionStateRef.current.currentCardIndex += 1;
            moveCurrentPlayer();
            getCardToBeAuctioned();
          }
        } else {
          if (
            auctionStateRef.current.currentCardIndex >=
            currentRoundCards - 1
          ) {
            auctionStateRef.current.roundIndex += 1;
            auctionStateRef.current.currentCardIndex = 0;
          } else {
            auctionStateRef.current.currentCardIndex += 1;
          }
          moveCurrentPlayer();
          getCardToBeAuctioned();
        }
      },
    });
  };

  const handlePass = () => {
    const tempAuctionStateRef = { ...auctionStateRef.current };

    if (
      !auctionStateRef.current?.skippedPlayers?.includes(
        tempAuctionStateRef?.currentPlayer?.userId as string,
      )
    ) {
      auctionStateRef.current?.skippedPlayers.push(
        tempAuctionStateRef?.currentPlayer?.userId as string,
      );
    }

    if (
      tempAuctionStateRef?.players?.length ===
        tempAuctionStateRef?.skippedPlayers.length &&
      auctionStateRef?.current?.highestBidder === null
    ) {
      handleCompleteCard();
    } else if (
      tempAuctionStateRef?.skippedPlayers.length ===
        tempAuctionStateRef?.players?.length - 1 &&
      auctionStateRef?.current?.highestBidder
    ) {
      handleCompleteCard(
        auctionStateRef?.current?.highestBidder?.userId as string,
      );
    } else {
      moveCurrentPlayer();
    }
  };

  useEffect(() => {
    document.body.classList.add('no-padding-wrapper');
    return () => {
      document.body.classList.remove('no-padding-wrapper');
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const user: any = auctionStateRef?.current?.players?.find(
      (user) => user.userId === currentUser?.id,
    );
    dispatch({
      type: AppActionType.setUserWallet,
      data: Math.floor(user?.walletBalance) || 0,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedUserCards: any = {};

    auctionStateRef?.current?.players.forEach((player) => {
      if (player?.cards) {
        updatedUserCards[player?.userId as string] = player.cards;
        dispatch({ type: AppActionType.setUserCards, data: updatedUserCards });
      }
    });
  }, [auctionStateRef?.current?.players]);
  const prioritizeId = useCallback(
    (arr: PlayerType[]) => {
      const matchedItem = arr.find((item) => item.userId === currentUser?.id);

      const remainingItems = arr.filter(
        (item) => item.userId !== currentUser?.id,
      );

      if (matchedItem) {
        return [matchedItem, ...remainingItems];
      }

      return arr;
    },
    [currentUser?.id],
  );
  return (
    <div className="game-board-screen">
      {contextHolder}
      <div className="exit-table">
        <div>
          {!isMobileBrowser(width) && (
            <div className="round-wrap">
              {' '}
              Pot Amount : {potAmount}{' '}
              <img className="bid-image" src={PotIcon} />{' '}
            </div>
          )}
          Total Rounds : {currentPusherUser?.rounds || 0}
          <br></br>
          Current Round : {(currentPusherUser?.roundIndex as number) + 1 || 0}
          <br></br>
        </div>
      </div>
      <div className="wallet-wrapper">
        <div className="d-flex full-width gap-4">
          <span className="d-flex align-center wallet-total">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#fff"
              viewBox="0 0 256 256"
            >
              <path d="M216,64H56a8,8,0,0,1,0-16H192a8,8,0,0,0,0-16H56A24,24,0,0,0,32,56V184a24,24,0,0,0,24,24H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64Zm0,128H56a8,8,0,0,1-8-8V78.63A23.84,23.84,0,0,0,56,80H216Zm-48-60a12,12,0,1,1,12,12A12,12,0,0,1,168,132Z"></path>
            </svg>
            {Math.floor(userWalletBalance || 0)}
          </span>
          <Button type="primary" onClick={handleAddWalletBalance}>
            Add {initialWalletBalance || 500}+
          </Button>
          <Button
            type="primary"
            className="deck-of-card-btn"
            onClick={showDeckOfCards}
          >
            <div className="d-flex align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="512"
                height="512"
                x="0"
                y="0"
                viewBox="0 0 50 50"
                xmlSpace="preserve"
                className="mr-4"
              >
                <g>
                  <path
                    fillRule="evenodd"
                    d="M10.969 8.276c.72.468 1.701-.042 1.701-.923 0-.692-.639-1.215-1.312-1.081.474-.72-.034-1.707-.919-1.707s-1.393.987-.919 1.707a1.104 1.104 0 0 0-1.312 1.081c0 .883.981 1.391 1.702.923l-.239.968h1.536zm12.016 22.063c-.721-.469-1.703.039-1.703.922 0 .692.639 1.216 1.312 1.081-.474.72.034 1.708.919 1.708s1.393-.987.919-1.708a1.103 1.103 0 0 0 1.312-1.081c0-.881-.981-1.391-1.702-.924l.239-.967h-1.536zm7.087 8.13V0H3.881v38.469zM27.249 2.823v32.822H6.705V2.823zm4.172 4.969 3.933.49-4.057 32.572-8.308-1.035H7.878l-.118.947 25.989 3.237L38.503 5.83l-7.082-.882zm7.802 3.174-.352 2.82 3.822.967-8.052 31.823-8.576-2.17-14.521-1.809-.248.98L36.684 50l9.435-37.289zM18.2 22.403c1.663 1.082 3.928-.097 3.928-2.132a2.544 2.544 0 0 0-3.03-2.495c1.098-1.663-.078-3.941-2.122-3.941s-3.22 2.278-2.122 3.941a2.543 2.543 0 0 0-3.029 2.495c0 2.037 2.266 3.214 3.93 2.128l-.552 2.236h3.546z"
                    clipRule="evenodd"
                    fill="#ffffff"
                    opacity="1"
                    data-original="#000000"
                    className=""
                  ></path>
                </g>
              </svg>
              <div className="fw-600"> Player List </div>
            </div>
          </Button>
          <Drawer
            title="Deck of Cards"
            onClose={() => setOpen(false)}
            open={open}
          >
            <DeckOfCards deckLoading={deckLoading} allCards={allCards} />
          </Drawer>
        </div>
        {isMobileBrowser(width) && (
          <div className="round-wrap">
            {' '}
            Pot Amount : {potAmount} <img className="bid-image" src={PotIcon} />{' '}
          </div>
        )}
        <div className="d-flex gap-4">
          {cardsPerRounds?.[currentPusherUser?.roundIndex || 0]?.map(
            (card, index) => (
              // eslint-disable-next-line react/jsx-key
              <div
                className={`current-player-card-list ${index === currentPusherUser?.currentCardIndex ? 'active-card' : ''}`}
              >
                <div className="c-player-dec ">
                  <div className="c-player-name">{card?.card?.name}</div>

                  <div className="c-player-bid-price d-flex align-center gap-4 justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ffffff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,152a48,48,0,1,1,48-48A48.05,48.05,0,0,1,128,176Zm39.21-98.53a63.66,63.66,0,0,0-31.21-13V40.37a87.6,87.6,0,0,1,48.28,20ZM120,64.52a63.66,63.66,0,0,0-31.21,13L71.72,60.4a87.6,87.6,0,0,1,48.28-20ZM77.47,88.79a63.66,63.66,0,0,0-13,31.21H40.37a87.6,87.6,0,0,1,20-48.28ZM64.52,136a63.66,63.66,0,0,0,13,31.21L60.4,184.28a87.6,87.6,0,0,1-20-48.28Zm24.27,42.53A63.66,63.66,0,0,0,120,191.48v24.15a87.6,87.6,0,0,1-48.28-20ZM136,191.48a63.66,63.66,0,0,0,31.21-12.95l17.07,17.07a87.6,87.6,0,0,1-48.28,20Zm42.53-24.27A63.66,63.66,0,0,0,191.48,136h24.15a87.6,87.6,0,0,1-20,48.28ZM191.48,120a63.66,63.66,0,0,0-12.95-31.21L195.6,71.72a87.6,87.6,0,0,1,20,48.28Z"></path>
                    </svg>
                    {card?.card?.value} Pts
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
      <div className="game-bottom-controller">
        <div className="player-list"></div>
        <div className="bid-wrapper">
          {(!isUniversalFlow
            ? currentPusherUser?.currentPlayer?.userId === currentUser?.id
            : true) &&
            bidTimer !== 0 &&
            bidTimer !== null && (
              <>
                <Segmented
                  onClick={() => {
                    if (bidValue) setBidValue(0);
                  }}
                  value={bidValue}
                  options={getBidValues(
                    highestBid,
                    appConfigurables?.find(
                      (config) => config?.key === 'bid_incrementals',
                    )?.value?.bidValues,
                  )}
                  onChange={(value) => {
                    handleBid(value);
                  }}
                />
                {!isUniversalFlow && (
                  <Button size="small" type="primary" onClick={handlePass}>
                    Pass
                  </Button>
                )}
              </>
            )}
        </div>
      </div>
      <div className="game-wrapper">
        <div className="game-table">
          <div className="current-player-card">
            <div className="table-name">
              {tableDetails?.tableNumber
                ? `Table No. ${tableDetails?.tableNumber}`
                : 'Private Table'}
            </div>
            <div className="c-player-dec">
              <div className="c-player-name">{auctionCard?.name}</div>

              {auctionCard?.value && (
                <div className="c-player-bid-price d-flex align-center gap-4 justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#ffffff"
                    viewBox="0 0 256 256"
                  >
                    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,152a48,48,0,1,1,48-48A48.05,48.05,0,0,1,128,176Zm39.21-98.53a63.66,63.66,0,0,0-31.21-13V40.37a87.6,87.6,0,0,1,48.28,20ZM120,64.52a63.66,63.66,0,0,0-31.21,13L71.72,60.4a87.6,87.6,0,0,1,48.28-20ZM77.47,88.79a63.66,63.66,0,0,0-13,31.21H40.37a87.6,87.6,0,0,1,20-48.28ZM64.52,136a63.66,63.66,0,0,0,13,31.21L60.4,184.28a87.6,87.6,0,0,1-20-48.28Zm24.27,42.53A63.66,63.66,0,0,0,120,191.48v24.15a87.6,87.6,0,0,1-48.28-20ZM136,191.48a63.66,63.66,0,0,0,31.21-12.95l17.07,17.07a87.6,87.6,0,0,1-48.28,20Zm42.53-24.27A63.66,63.66,0,0,0,191.48,136h24.15a87.6,87.6,0,0,1-20,48.28ZM191.48,120a63.66,63.66,0,0,0-12.95-31.21L195.6,71.72a87.6,87.6,0,0,1,20,48.28Z"></path>
                  </svg>
                  {auctionCard?.value} Pts
                </div>
              )}

              <div className="c-player-bid-price mt-6">
                <img src={BidAvatar} className="bid-image" />
                <span>{highestBid}</span>
              </div>

              <div className="c-player-bid-price">{bidTimer} seconds</div>
            </div>
          </div>
          {prioritizeId(auctionStateRef?.current?.players)?.map(
            (player: PlayerType, index: Key | null | undefined) => (
              <Player
                key={index}
                player={player}
                index={index as number}
                totalPlayers={playersData.length}
                currentPusherUser={currentPusherUser}
                allCards={allCards}
                isUniversalFlow={isUniversalFlow}
                highestBidder={auctionStateRef?.current?.highestBidder}
                highestBid={auctionStateRef?.current?.highestBid}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default GameScreen;
