/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "mutation\n  getUser($data: GetUserInput) {\n  getUser(data: $data) {\n    data {\n      id\n      phoneNumber\n      userName\n     }\n    }\n  }\n": types.GetUserDocument,
    "mutation CreatePrivateTable($userId: String!) {\n  createPrivateTable(userId: $userId) {\n    data {\n      id\n    }\n  }\n}\n": types.CreatePrivateTableDocument,
    "mutation AddUsersToTable($addUsersToTableData: AddUsersToTableInput!) {\n  addUsersToTable(data: $addUsersToTableData) {\n        message\n         data {\n          slug\n          tableId\n          gameSessionUsers {\n            gameSessionId\n\n          }\n        }\n     }\n  }\n": types.AddUsersToTableDocument,
    "mutation addMoneyToWallet($data: AddMoneyInput) {\n    addMoneyToWallet(data: $data) {\n      data {\n        balance\n      }\n      message\n    }\n}\n": types.AddMoneyToWalletDocument,
    "mutation StartGame($startGameId: String!) {\n  startGame(id: $startGameId) {\n    data {\n        card {\n          id\n          name\n        }\n      userId\n      cardId\n    }\n  }\n}\n": types.StartGameDocument,
    "mutation StartCounter($data: StartCounterInput) {\n  startCounter(data: $data) {\n        __typename\n\n  }\n}\n": types.StartCounterDocument,
    "mutation PlaceBid($data: PlaceBidInput) {\n  placeBid (data: $data){\n    data {\n      amount\n      cardId\n      userId\n    }\n  }\n}\n": types.PlaceBidDocument,
    "mutation CompleteCardAuction($data: CompleteCardAuctionInputType) {\n  completeCardAuction(data: $data) {\n    message\n    data{\n    userId\n    balance\n    }\n  }\n}\n": types.CompleteCardAuctionDocument,
    "mutation CompletGame($gameSessionId: String!) {\n  completeGame(gameSessionId: $gameSessionId) {\n    data {\n      amount\n      points\n      position\n      userId\n    }\n  }\n}\n": types.CompletGameDocument,
    "query GetTables {\n  getTables {\n    data {\n    id\n    createdBy\n      isActive\n      gameSessions {\n      id\n        gameId\n      status\n      tableId\n            gameSessionUsers {\n        gameSessionId\n        user {\n            id\n        }\n}\n\n      }\n      slug\n      status\n    }\n  }\n}": types.GetTablesDocument,
    "query GetUserWallet($userId: ID!) {\n  getUserWallet(userId: $userId) {\n    data {\n     balance\n     userId\n    }\n  }\n}\n": types.GetUserWalletDocument,
    "query GetGameSessionUsers($gameSessionId: String!) {\n  getGameSessionUsers(gameSessionId: $gameSessionId) {\n      data {\n      isHost\n      gameSessionId\n      user{\n        id\n        userName\n         wallet {\n          balance\n        }\n      }\n    }\n  }\n  }\n": types.GetGameSessionUsersDocument,
    "query getAllCards {\n  getAllCards {\n    data {\n      id\n      name\n      value\n      copies\n    }\n  }\n}\n": types.GetAllCardsDocument,
    "query getAuctionCard($data: GetAuctionCardInputType) {\n  getAuctionCard(data: $data) {\n    data {\n      id\n      cardId\n        card {\n        name\n        value\n\n      }\n\n    }\n  }\n}\n": types.GetAuctionCardDocument,
    "query GetConfig {\n  getConfig{\n    data {\n      key\n      value {\n        amount\n        default\n        total\n        round1\n        round2\n        round3\n        default\n        min\n        max\n        bidValues {\n          amount\n          values\n        }\n        isUniversal\n      }\n\n    }\n  }\n}\n": types.GetConfigDocument,
    "query GetLastBid($data: GetLastBidInputType){\n  getLastBid (data: $data){\n\n    data {\n      amount\n      cardId\n    }\n  }\n}\n": types.GetLastBidDocument,
    "query getGameSession ($gameSessionId: String!) {\n  getGameSession(gameSessionId: $gameSessionId) {\n    data {\n      potAmount\n    }\n  }\n}\n": types.GetGameSessionDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation\n  getUser($data: GetUserInput) {\n  getUser(data: $data) {\n    data {\n      id\n      phoneNumber\n      userName\n     }\n    }\n  }\n"): (typeof documents)["mutation\n  getUser($data: GetUserInput) {\n  getUser(data: $data) {\n    data {\n      id\n      phoneNumber\n      userName\n     }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation CreatePrivateTable($userId: String!) {\n  createPrivateTable(userId: $userId) {\n    data {\n      id\n    }\n  }\n}\n"): (typeof documents)["mutation CreatePrivateTable($userId: String!) {\n  createPrivateTable(userId: $userId) {\n    data {\n      id\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation AddUsersToTable($addUsersToTableData: AddUsersToTableInput!) {\n  addUsersToTable(data: $addUsersToTableData) {\n        message\n         data {\n          slug\n          tableId\n          gameSessionUsers {\n            gameSessionId\n\n          }\n        }\n     }\n  }\n"): (typeof documents)["mutation AddUsersToTable($addUsersToTableData: AddUsersToTableInput!) {\n  addUsersToTable(data: $addUsersToTableData) {\n        message\n         data {\n          slug\n          tableId\n          gameSessionUsers {\n            gameSessionId\n\n          }\n        }\n     }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation addMoneyToWallet($data: AddMoneyInput) {\n    addMoneyToWallet(data: $data) {\n      data {\n        balance\n      }\n      message\n    }\n}\n"): (typeof documents)["mutation addMoneyToWallet($data: AddMoneyInput) {\n    addMoneyToWallet(data: $data) {\n      data {\n        balance\n      }\n      message\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation StartGame($startGameId: String!) {\n  startGame(id: $startGameId) {\n    data {\n        card {\n          id\n          name\n        }\n      userId\n      cardId\n    }\n  }\n}\n"): (typeof documents)["mutation StartGame($startGameId: String!) {\n  startGame(id: $startGameId) {\n    data {\n        card {\n          id\n          name\n        }\n      userId\n      cardId\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation StartCounter($data: StartCounterInput) {\n  startCounter(data: $data) {\n        __typename\n\n  }\n}\n"): (typeof documents)["mutation StartCounter($data: StartCounterInput) {\n  startCounter(data: $data) {\n        __typename\n\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation PlaceBid($data: PlaceBidInput) {\n  placeBid (data: $data){\n    data {\n      amount\n      cardId\n      userId\n    }\n  }\n}\n"): (typeof documents)["mutation PlaceBid($data: PlaceBidInput) {\n  placeBid (data: $data){\n    data {\n      amount\n      cardId\n      userId\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation CompleteCardAuction($data: CompleteCardAuctionInputType) {\n  completeCardAuction(data: $data) {\n    message\n    data{\n    userId\n    balance\n    }\n  }\n}\n"): (typeof documents)["mutation CompleteCardAuction($data: CompleteCardAuctionInputType) {\n  completeCardAuction(data: $data) {\n    message\n    data{\n    userId\n    balance\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation CompletGame($gameSessionId: String!) {\n  completeGame(gameSessionId: $gameSessionId) {\n    data {\n      amount\n      points\n      position\n      userId\n    }\n  }\n}\n"): (typeof documents)["mutation CompletGame($gameSessionId: String!) {\n  completeGame(gameSessionId: $gameSessionId) {\n    data {\n      amount\n      points\n      position\n      userId\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetTables {\n  getTables {\n    data {\n    id\n    createdBy\n      isActive\n      gameSessions {\n      id\n        gameId\n      status\n      tableId\n            gameSessionUsers {\n        gameSessionId\n        user {\n            id\n        }\n}\n\n      }\n      slug\n      status\n    }\n  }\n}"): (typeof documents)["query GetTables {\n  getTables {\n    data {\n    id\n    createdBy\n      isActive\n      gameSessions {\n      id\n        gameId\n      status\n      tableId\n            gameSessionUsers {\n        gameSessionId\n        user {\n            id\n        }\n}\n\n      }\n      slug\n      status\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetUserWallet($userId: ID!) {\n  getUserWallet(userId: $userId) {\n    data {\n     balance\n     userId\n    }\n  }\n}\n"): (typeof documents)["query GetUserWallet($userId: ID!) {\n  getUserWallet(userId: $userId) {\n    data {\n     balance\n     userId\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetGameSessionUsers($gameSessionId: String!) {\n  getGameSessionUsers(gameSessionId: $gameSessionId) {\n      data {\n      isHost\n      gameSessionId\n      user{\n        id\n        userName\n         wallet {\n          balance\n        }\n      }\n    }\n  }\n  }\n"): (typeof documents)["query GetGameSessionUsers($gameSessionId: String!) {\n  getGameSessionUsers(gameSessionId: $gameSessionId) {\n      data {\n      isHost\n      gameSessionId\n      user{\n        id\n        userName\n         wallet {\n          balance\n        }\n      }\n    }\n  }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getAllCards {\n  getAllCards {\n    data {\n      id\n      name\n      value\n      copies\n    }\n  }\n}\n"): (typeof documents)["query getAllCards {\n  getAllCards {\n    data {\n      id\n      name\n      value\n      copies\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getAuctionCard($data: GetAuctionCardInputType) {\n  getAuctionCard(data: $data) {\n    data {\n      id\n      cardId\n        card {\n        name\n        value\n\n      }\n\n    }\n  }\n}\n"): (typeof documents)["query getAuctionCard($data: GetAuctionCardInputType) {\n  getAuctionCard(data: $data) {\n    data {\n      id\n      cardId\n        card {\n        name\n        value\n\n      }\n\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetConfig {\n  getConfig{\n    data {\n      key\n      value {\n        amount\n        default\n        total\n        round1\n        round2\n        round3\n        default\n        min\n        max\n        bidValues {\n          amount\n          values\n        }\n        isUniversal\n      }\n\n    }\n  }\n}\n"): (typeof documents)["query GetConfig {\n  getConfig{\n    data {\n      key\n      value {\n        amount\n        default\n        total\n        round1\n        round2\n        round3\n        default\n        min\n        max\n        bidValues {\n          amount\n          values\n        }\n        isUniversal\n      }\n\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetLastBid($data: GetLastBidInputType){\n  getLastBid (data: $data){\n\n    data {\n      amount\n      cardId\n    }\n  }\n}\n"): (typeof documents)["query GetLastBid($data: GetLastBidInputType){\n  getLastBid (data: $data){\n\n    data {\n      amount\n      cardId\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query getGameSession ($gameSessionId: String!) {\n  getGameSession(gameSessionId: $gameSessionId) {\n    data {\n      potAmount\n    }\n  }\n}\n"): (typeof documents)["query getGameSession ($gameSessionId: String!) {\n  getGameSession(gameSessionId: $gameSessionId) {\n    data {\n      potAmount\n    }\n  }\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;