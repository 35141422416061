import { useMutation } from '@apollo/client';
import { Button, Card, message } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { PUSHER_CHANNELS, PUSHER_EVENTS, ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import pusher from '../../pusher';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { ADD_USER_TO_TABLE } from '../auth/graphql/mutations';

type TableCardPropType = {
  index: number;
  tableId: string;
  tableSlug: string;
  sessionUserCount: number;
  onGoingGame: boolean;
};

const TableCard = ({
  index,
  tableId,
  tableSlug,
  sessionUserCount,
  onGoingGame,
}: TableCardPropType) => {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext) as AppContextType;
  const { navigate } = useRouter();

  const [addUserMutate, { loading: userLoading }] = useMutation(
    ADD_USER_TO_TABLE,
    {
      onError() {},
    },
  );
  const [userCount, setUserCount] = useState(sessionUserCount);

  const addUserToTable = () => {
    if (tableId && currentUser?.id) {
      addUserMutate({
        variables: {
          addUsersToTableData: {
            tableId: tableId,
            users: [{ userId: currentUser?.id }],
          },
        },
        onCompleted: (response) => {
          if (response?.addUsersToTable?.data) {
            dispatch({
              type: AppActionType.setTableDetails,
              data: {
                tableNumber: index + 1,
                tableId: tableId,
                tableSlug: tableSlug,
                gameSessionId:
                  response?.addUsersToTable?.data?.gameSessionUsers?.[0]
                    ?.gameSessionId || '',
              },
            });
            navigate(`${ROUTES.GAMES}/${tableId}`);
          }
        },
      });
    } else {
      message.error('user cannot be added');
    }
  };

  useEffect(() => {
    const channel = pusher.subscribe(
      `${PUSHER_CHANNELS.PRESENCE_TABLE}-${tableSlug}`,
    );

    channel.bind(PUSHER_EVENTS.USER_COUNT, function (data: { count: number }) {
      setUserCount(data?.count);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);
  return (
    <div>
      <Card>
        <Meta title={`Table ${index + 1}`} />
        <div className="d-flex align-center justify-between">
          <div className="opacity-6">{userCount} Players</div>
        </div>
        <Button
          type="primary"
          loading={userLoading}
          onClick={addUserToTable}
          disabled={onGoingGame || userCount >= 6}
        >
          {onGoingGame ? 'Game started' : 'Join'}{' '}
        </Button>
      </Card>
    </div>
  );
};

export default TableCard;
