import { Button, Modal } from 'antd';
import './PrivateLinkModal.less';
type PrivateLinkModalProps = {
  handleJoinTable: () => void;
  handleCopyLink: () => void;
  isModalOpen: boolean;
  linkText: string;
  // eslint-disable-next-line no-unused-vars
  setIsModalOpen: (parameter: boolean) => void;
};
const PrivateLinkModal = ({
  handleJoinTable,
  handleCopyLink,
  isModalOpen,
  linkText = '',
  setIsModalOpen,
}: PrivateLinkModalProps) => {
  return (
    <div>
      <Modal
        className="common-modal"
        title="Private game link"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="submit" onClick={handleCopyLink}>
            Copy Link
          </Button>,
          <Button key="back" type="primary" onClick={handleJoinTable}>
            Copy Link & Join Table
          </Button>,
        ]}
      >
        {linkText}
      </Modal>
    </div>
  );
};

export default PrivateLinkModal;
