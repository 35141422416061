import { Layout } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import useRouter from '../hooks/useRouter';
import { AppContextType } from '../types/appContext.type';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';

const { Content } = Layout;
const App = () => {
  const {
    state: { hasGameStarted },
  } = useContext(AppContext) as AppContextType;
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  const { navigate } = useRouter();
  const redirectToHome = () => {
    navigate(ROUTES.MAIN);
  };
  return (
    <Layout>
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Layout className="site-layout">
        {!hasGameStarted && (
          <AppHeader>
            <div className="header-wrapper">
              <div className="logo pointer" onClick={redirectToHome}>
                {/* <img src={Logo} height={44} /> */}
                <h4 className="text-heading m-0">Eon Games</h4>
              </div>
              <UserProfile />
            </div>
          </AppHeader>
        )}
        <Content className={`wrapper  ${hasGameStarted ? 'p-0' : ''}`}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
