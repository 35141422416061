import { gql } from '../../../__generated__';

export const GET_GAME_TABLES = gql(`query GetTables {
  getTables {
    data {
    id
    createdBy
      isActive
      gameSessions {
      id
        gameId
      status
      tableId
            gameSessionUsers {
        gameSessionId
        user {
            id
        }
}

      }
      slug
      status
    }
  }
}`);

export const GET_USER_WALLET = gql(`query GetUserWallet($userId: ID!) {
  getUserWallet(userId: $userId) {
    data {
     balance
     userId
    }
  }
}
`);

export const GET_SESSION_USERS =
  gql(`query GetGameSessionUsers($gameSessionId: String!) {
  getGameSessionUsers(gameSessionId: $gameSessionId) {
      data {
      isHost
      gameSessionId
      user{
        id
        userName
         wallet {
          balance
        }
      }
    }
  }
  }
`);

export const GET_ALL_CARDS = gql(`query getAllCards {
  getAllCards {
    data {
      id
      name
      value
      copies
    }
  }
}
`);

export const GET_AUCTION_CARD =
  gql(`query getAuctionCard($data: GetAuctionCardInputType) {
  getAuctionCard(data: $data) {
    data {
      id
      cardId
        card {
        name
        value

      }

    }
  }
}
`);

export const GET_CONFIGURABLES = gql(`query GetConfig {
  getConfig{
    data {
      key
      value {
        amount
        default
        total
        round1
        round2
        round3
        default
        min
        max
        bidValues {
          amount
          values
        }
        isUniversal
      }

    }
  }
}
`);

export const GET_LAST_BID = gql(`query GetLastBid($data: GetLastBidInputType){
  getLastBid (data: $data){

    data {
      amount
      cardId
    }
  }
}
`);

export const GET_GAME_SESSION =
  gql(`query getGameSession ($gameSessionId: String!) {
  getGameSession(gameSessionId: $gameSessionId) {
    data {
      potAmount
    }
  }
}
`);
