import { RcFile } from 'antd/es/upload';
import { NavigateFunction } from 'react-router-dom';
import { BidValuesType, Maybe } from '../__generated__/graphql';
import { messageContext } from '../components/AppContextHolder';
import { MOBILE_DEVICE_BREAKPOINT, REGEX } from './constants';

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(_rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER?.test(Number(value).toString())) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
};

export const getBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (
  img: Blob,
  // eslint-disable-next-line no-unused-vars
  callback: (result: string | ArrayBuffer | null) => void,
) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    messageContext.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file?.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    messageContext.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const handleProtectedNavigation = (
  allow: boolean,
  callback: NavigateFunction,
  path: number,
) => (allow ? callback(path) : false);

// Portal related methods
export const injectUsingPortal = (portalId: string) =>
  // eslint-disable-next-line no-undef
  document.getElementById(portalId);

export const isPortalIdExists = (portalId: string) =>
  !!injectUsingPortal(portalId);

// create cookie
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (
  name: string,
  value: string | number | boolean,
  minutes: number,
) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date?.setTime(date?.getTime() + minutes * 60 * 1000);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expires = `; expires=${(date as any)?.toGMTString()}`; //as using toGMTString
  }
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=${value}${expires}; path=/`;
};

// retrieve cookie
export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  let returnCookie = '';
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document?.cookie);
  const cookieArray = decodedCookie?.split(';');
  if (cookieArray?.length > 0) {
    // eslint-disable-next-line array-callback-return
    cookieArray?.map((item) => {
      let cookie = item;
      while (cookie?.charAt(0) === ' ') {
        cookie = cookie?.substring(1);
      }
      if (cookie?.indexOf(name) === 0) {
        returnCookie = cookie?.substring(name?.length, cookie?.length);
      }
    });
    return returnCookie;
  }
};

// force expired cookie or delete
export const deleteCookie = (name: string) => {
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const formItemProps = { normalize: (value: string) => value?.trim() };

export function getBidValues(
  currentAmount: number,
  config?: Maybe<Maybe<BidValuesType>[]> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const matchingBidValues = config?.find((bidValue) =>
    currentAmount < 251
      ? currentAmount <= (bidValue?.amount || 0)
      : bidValue?.amount === 251,
  )?.values;
  const formattedBidValues = matchingBidValues?.map((value) => ({
    label: `+${value}`,
    value: value,
  }));

  return formattedBidValues || [];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMobileBrowser = (width: any) => {
  if (width <= MOBILE_DEVICE_BREAKPOINT) {
    return true;
  }
  return false;
};
