export const themeConfig = {
  token: {
    colorPrimary: 'var(--color-primary)',
    radiusBase: '4px',
    controlRadius: '4px',
    colorLink: 'var(--color-primary)',
    colorLinkActive: 'var(--color-primary)',
    colorLinkHover: 'var(--color-primary)',
    fontFamily: "'Rubik', sans-serif",
  },
  components: {
    Typography: {},
    Button: {
      borderRadius: 4,
      colorPrimaryHover: 'var(--secondary-gradient)',
      colorPrimary: 'var(--primary-gradient)',
      primaryShadow: 'unset',
      colorPrimaryActive: 'var(--secondary-gradient)',
    },

    Menu: { itemSelectedBg: 'var(--color-grey-light)' },
    Card: {
      colorBgContainer: 'var(--primary-grey)',
      colorText: 'var(--color-primary-text)',
      colorTextDescription: 'var(--color-primary-text)',
      colorTextHeading: 'var(--color-primary-text)',
    },
    Layout: {
      colorText: 'var(--color-primary-text)',
    },

    List: {
      colorText: 'var(--color-primary-text)',
      colorTextDescription: 'var(--color-primary-text)',
    },
    Segmented: {
      colorText: 'black',
      colorBgLayout: 'white',
      colorTextLabel: 'black',
      colorFillSecondary: 'none',
      trackPadding: '10',
      colorBorder: 'red',
    },
    Drawer: {
      colorBgElevated: 'var(--primary-grey)',
      colorText: 'var(--color-primary-text)',
    },
  },
};
