import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import { useContext, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { AppContext } from '../../AppContext';
import {
  CALLBACK_URL,
  RARIO_USER_DETAILS,
  ROUTES,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import pusher from '../../pusher';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import './auth.less';
import { LOGIN } from './graphql/mutations';
const { required } = formValidatorRules;

const Login = () => {
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });
  type UserSessionData = {
    phoneNumber: string;
    userName: string;
  };
  const { dispatch } = useContext(AppContext) as AppContextType;
  const [form] = Form.useForm<UserSessionData>();
  const { navigate } = useRouter();
  const [phoneNumber, setPhoneNumber] = useState('');
  function successCallback() {
    pusher.signin();

    const callbackUrl = sessionStorage?.getItem(CALLBACK_URL);

    if (callbackUrl?.includes(ROUTES.PRIVATE)) {
      window.location.href = callbackUrl;
    } else {
      window.location.reload();
      navigate(ROUTES.MAIN);
    }
  }

  const onFinish = (formValues: UserSessionData) => {
    loginMutate({
      variables: {
        data: {
          userName: formValues?.userName,
          phoneNumber: phoneNumber,
        },
      },
      onCompleted: (response) => {
        if (response?.getUser?.data) {
          sessionStorage.setItem(
            RARIO_USER_DETAILS,
            JSON.stringify(response?.getUser?.data),
          );
          dispatch({
            type: AppActionType.setCurrentUser,
            data: response?.getUser?.data,
          });
          successCallback();
        }
      },
    });
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="5"
            viewBox="0 0 65 5"
            className="vector"
          >
            <path
              d="M968,5630h65l-4,5H972Z"
              transform="translate(-968 -5630)"
            ></path>
          </svg>
          <Spin spinning={false} wrapperClassName="full-width">
            <div className="text-center mb-48 mt-32">
              <h1 className="text-heading">Eon Games </h1>
            </div>

            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item name="userName" rules={[required]}>
                <Input prefix={<UserOutlined />} placeholder="Enter Username" />
              </Form.Item>
              <Form.Item name="phoneNumber" className="mb-8" rules={[required]}>
                <div className="phone-input-wrapper">
                  <ReactPhoneInput
                    placeholder="Enter phone number"
                    country="in"
                    enableSearch
                    disableSearchIcon
                    onChange={(e) => setPhoneNumber(e)}
                  />
                </div>
              </Form.Item>
              <Form.Item className=" full-width mb-8">
                <Button
                  type="primary"
                  className="full-width login-btn"
                  htmlType="submit"
                  loading={loginLoading}
                >
                  Start Game
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  );
};

export default Login;
