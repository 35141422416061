import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useLocation } from 'react-router-dom';
import { CompleteGameData } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import UserAvatar from '../../assets/images/user-avatar.jpg';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { PlayerType } from '../../types/common.type';
import './Conclusion.less';
import Crown from './Crown';
import { GET_SESSION_USERS } from './graphql/queries';

import { Avatar, message } from 'antd';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
let restartGameTime = 0;

const Conclusion = () => {
  useEffect(() => {
    document.body.classList.add('no-padding-wrapper');

    return () => {
      document.body.classList.remove('no-padding-wrapper');
    };
  }, []);

  const {
    state: { playerCards, currentUser, tableDetails },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const [playersData, setPlayersData] = useState<PlayerType[]>([]);

  const [getSessionUsers] = useLazyQuery(GET_SESSION_USERS, {
    fetchPolicy: 'network-only',
    variables: { gameSessionId: tableDetails?.gameSessionId || '' },
    onCompleted: ({ getGameSessionUsers }) => {
      if (getGameSessionUsers?.data) {
        const playerState: PlayerType[] = getGameSessionUsers?.data?.reduce(
          (acc: PlayerType[], player) => {
            if (player?.user?.id) {
              acc.push({
                userName: player?.user?.userName || '',
                cards: playerCards?.[player?.user?.id] || [],
                hasPassed: false,
                userId: player?.user?.id,
                isHost: player?.isHost || false,
                walletBalance: Math.floor(player?.user?.wallet?.balance || 0),
              });
            }
            return acc;
          },
          [] as PlayerType[],
        );
        setPlayersData(playerState);
      }
    },
  });

  useEffect(() => {
    if (tableDetails?.gameSessionId) {
      getSessionUsers();
    }
  }, [tableDetails?.gameSessionId]);

  const {
    navigate,
    location: { pathname },
  } = useRouter();

  const [timeLeft, setTimeLeft] = useState(restartGameTime);

  useEffect(() => {
    if (playersData?.length !== 0) {
      const isHost = playersData.some(
        (player) => player?.userId === currentUser?.id && player?.isHost,
      );
      restartGameTime = isHost ? 10 : 15;
      setTimeLeft(restartGameTime);

      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      const timeoutId = setTimeout(() => {
        dispatch({
          type: AppActionType.setTableDetails,
          data: {
            ...tableDetails,
            gameSessionId: undefined,
          },
        });
        message.success('restarting game');
        if (pathname?.includes(ROUTES.PRIVATE)) {
          navigate(`/${ROUTES.PRIVATE}/${tableDetails?.tableId}`, {
            state: { restartGame: true },
          });
        } else {
          navigate(`/${ROUTES.GAMES}/${tableDetails?.tableId}`, {
            state: { restartGame: true },
          });
        }
      }, restartGameTime * 1000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [playersData]);

  const { state } = useLocation();
  return (
    <>
      <div className="leader-board-section">
        <ReactConfetti width={1900} height={1900} />
        <div className="container">
          <div className="leader-board-wrapper">
            <div className="leader-board-list">
              <div className="leaderbord-title">
                <span>Player ranking </span>
                <h2>LEADERBOARD</h2>
              </div>
              <ul>
                {state?.data?.map((item: CompleteGameData, index: number) => (
                  <li key={index}>
                    <div
                      className={`d-flex align-center gap-8 ${item?.position === 1 ? 'active' : ''}`}
                    >
                      <div className="d-flex align-center gap-8">
                        <div className="relative user-img">
                          <Avatar size={56} src={UserAvatar} />
                          {item?.position === 1 && <Crown />}
                        </div>
                        <div className="player-description">
                          <h5>
                            {
                              playersData?.find(
                                (sessionUser) =>
                                  sessionUser?.userId === item?.userId,
                              )?.userName
                            }
                          </h5>
                          <span className="opacity-6">
                            {item.points || 0} points
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="opacity-6">
                        winning amount {Math.floor(item.amount || 0) || 0}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-20">
              <h2>
                Total Pot amount collected: {Math.floor(state?.potAmount) || 0}
              </h2>
            </div>

            <h1>Next Game starting in {timeLeft} seconds</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Conclusion;
