import { useLazyQuery, useMutation } from '@apollo/client';
import { message, Spin } from 'antd';
import { capitalize } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TableCardType, UserType } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { PUSHER_CHANNELS, PUSHER_EVENTS, ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import pusher from '../../pusher';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { ADD_USER_TO_TABLE, START_GAME } from '../auth/graphql/mutations';
import { GET_SESSION_USERS } from './graphql/queries';
import './WaitingScreen.less';

type UserCards = {
  [userId: string]: string[];
};

const WaitingScreen = () => {
  const {
    dispatch,
    state: { tableDetails, currentUser, appConfigurables },
  } = useContext(AppContext) as AppContextType;

  const gamePlayers = appConfigurables?.find(
    (config) => config?.key === 'game_players',
  );

  const [startGameMutate] = useMutation(START_GAME, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  const rounds = appConfigurables?.find(
    (config) => config?.key === 'number_of_rounds',
  );

  const totalRounds = rounds?.value?.total || 3;
  const cardsPerRound = [
    rounds?.value?.round1 || 3,
    rounds?.value?.round2 || 2,
    rounds?.value?.round3 || 2,
  ];

  const { params } = useRouter();
  const [gameHost, setGameHost] = useState<UserType>();
  const [userCount, setUserCount] = useState(0);

  const [getSessionUsers, { loading: sessionLoading }] =
    useLazyQuery(GET_SESSION_USERS);

  useEffect(() => {
    if (tableDetails?.gameSessionId) {
      getSessionUsers({
        variables: {
          gameSessionId: tableDetails?.gameSessionId,
        },
        onCompleted: (response) => {
          if (response?.getGameSessionUsers?.data) {
            const hosts = response?.getGameSessionUsers?.data?.filter(
              (item) => item?.isHost === true,
            );

            setGameHost(hosts?.[0]?.user || {});
            setUserCount(response?.getGameSessionUsers?.data?.length);
          }
        },
      });
    }
  }, [tableDetails?.gameSessionId]);

  const handleGameStart = () => {
    if (tableDetails?.gameSessionId) {
      startGameMutate({
        variables: { startGameId: tableDetails?.gameSessionId },
        onCompleted: (response) => {
          if (response?.startGame?.data) {
            dispatch({
              type: AppActionType.setTableDetails,
              data: {
                ...tableDetails,
                gameSessionId: tableDetails?.gameSessionId,
              },
            });
          }
        },
      });
    } else {
      message.error('Game session not found');
    }
  };
  type GameData = {
    id: string;
    gameSessionId: string;
    userId: string;
    gameId: string;
    isHost: boolean;
    position: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
  };
  const { state } = useLocation();

  const [addUserMutate] = useMutation(ADD_USER_TO_TABLE, {
    onError() {},
  });
  const addUserToTable = () => {
    const tableId = params?.tableId;
    if (tableId && currentUser?.id) {
      addUserMutate({
        variables: {
          addUsersToTableData: {
            tableId: tableId,
            users: [{ userId: currentUser?.id }],
          },
        },
        onCompleted: (response) => {
          if (response?.addUsersToTable) {
            dispatch({
              type: AppActionType.setTableDetails,
              data: {
                tableNumber: tableDetails?.tableNumber,
                tableId: tableId,
                tableSlug: response?.addUsersToTable?.data?.slug as string,
                gameSessionId: response?.addUsersToTable?.data
                  ?.gameSessionUsers?.[0]?.gameSessionId as string,
              },
            });
          }
        },
      });
    } else {
      message.error('user cannot be added');
    }
  };

  const {
    location: { pathname },
  } = useRouter();

  useEffect(() => {
    dispatch({
      type: AppActionType.setCardsPerRound,
      data: null,
    });

    dispatch({ type: AppActionType.gameStarted, data: false });

    dispatch({
      type: AppActionType.setAuctionRefData,
      data: {
        players: [],
        currentPlayer: null,
        currentCardIndex: 0,
        currentPlayerIndex: 0,
        rounds: rounds?.value?.total || 3,
        cardsPerRound: [
          rounds?.value?.round1 || 3,
          rounds?.value?.round2 || 2,
          rounds?.value?.round3 || 2,
        ],
        roundIndex: 0,
        highestBid: 0,
        highestBidder: null,
        skippedPlayers: [],
        currentAutionCard: null,
      },
    });
    dispatch({ type: AppActionType.setUserCards, data: null });

    if (pathname?.includes(ROUTES.PRIVATE)) {
      dispatch({
        type: AppActionType.setTableDetails,
        data: {
          ...tableDetails,
          tableNumber: undefined,
        },
      });
    }
    if (pathname?.includes(ROUTES.PRIVATE) || state?.restartGame)
      addUserToTable();
  }, []);

  useEffect(() => {
    if (tableDetails?.tableSlug) {
      const userChannel = pusher.subscribe(
        `${PUSHER_CHANNELS.PRESENCE_TABLE}-${tableDetails?.tableSlug}`,
      );

      userChannel?.bind(
        PUSHER_EVENTS.USER_COUNT,
        function ({ count, users }: { count: number; users: GameData[] }) {
          setUserCount(count);

          const hosts = users?.filter((item) => item?.isHost === true);

          dispatch({
            type: AppActionType.setTableDetails,
            data: {
              ...tableDetails,
              gameSessionId: hosts?.[0]?.gameSessionId,
            },
          });
        },
      );

      const gameChannel = pusher.subscribe(
        `${PUSHER_CHANNELS.GAME_SESSION}-${tableDetails?.tableSlug}`,
      );

      gameChannel.bind(
        PUSHER_EVENTS.START_GAME,
        function ({ data }: { data: TableCardType[] }) {
          const userCards: UserCards = data?.reduce((acc, item) => {
            if (item.userId) {
              if (!acc[item.userId]) {
                acc[item.userId] = [];
              }
              acc[item.userId]?.push(item?.cardId);
            }
            return acc;
          }, {} as UserCards);

          const pendingCards = data?.filter((card) => card.userId === null);

          let startIndex = 0;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const distributedRounds = {} as any;

          for (let i = 0; i < totalRounds; i++) {
            const roundKey = i;
            const numCards = cardsPerRound[i];
            distributedRounds[roundKey] = pendingCards.slice(
              startIndex,
              startIndex + numCards,
            );
            startIndex += numCards;
          }

          dispatch({
            type: AppActionType.setCardsPerRound,
            data: distributedRounds,
          });

          dispatch({ type: AppActionType.setUserCards, data: userCards });
          dispatch({ type: AppActionType.gameStarted, data: true });
        },
      );

      return () => {
        userChannel.unbind_all();
        userChannel.unsubscribe();
        gameChannel.unbind_all();
        gameChannel.unsubscribe();
      };
    }
  }, [tableDetails?.tableSlug]);

  useEffect(() => {
    setTimeout(() => {
      if (
        userCount >= (gamePlayers?.value?.min || 4) &&
        gameHost?.id === currentUser?.id
      ) {
        handleGameStart();
      }
    }, 1000);
  }, [userCount]);

  return sessionLoading ? (
    <LoaderComponent />
  ) : (
    <div className="container">
      <div className="waiting-screen-wrap">
        <h1>
          {tableDetails?.tableNumber
            ? `Table No. ${tableDetails?.tableNumber}`
            : 'Private Table'}
        </h1>
        <div className="heading-text">
          {capitalize(gameHost?.userName || '')} is the host
        </div>
        <div className="heading-text">
          {userCount === 1
            ? `${userCount} user has joined`
            : `${userCount} users have joined`}
        </div>
        <div className="heading-text">
          {userCount < (gamePlayers?.value?.min || 4)
            ? `Waiting for ${(gamePlayers?.value?.min || 4) - userCount} more users to join`
            : 'Wating for host to start the game'}
        </div>
        <Spin size="large" className="mt-32" />
      </div>
    </div>
  );
};

export default WaitingScreen;
