import { Layout } from 'antd';
import { ChildrenPropType } from '../../../types/common.type';

const { Header } = Layout;

const AppHeader = ({ children }: ChildrenPropType) => {
  return (
    <Header className="site-layout-sub-header-background app-header">
      {children}
    </Header>
  );
};
export default AppHeader;
