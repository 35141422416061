/* eslint-disable no-unused-vars */
import { Dispatch } from 'react';
import { ConfigType, UserType } from '../__generated__/graphql';
import { AuctionState, DistributedRounds, UserCards } from './common.type';

export type TableDetailsType = {
  tableId?: string;
  tableSlug?: string;
  gameSessionId?: string;
  tableNumber?: number;
};

export type AppState = {
  currentUser: UserType;
  authenticated: boolean;
  authToken: string | null;
  currentRole?: string;
  refreshToken?: string | null;
  hasGameStarted: boolean;
  userWalletBalance: number;
  tableDetails: TableDetailsType | null;
  playerCards: UserCards | null;
  appConfigurables: (ConfigType | null)[] | null;
  cardsPerRounds: DistributedRounds | null;
  auctionRefData: AuctionState;
};

export enum AppActionType {
  setCurrentUser = 'SET_CURRENT_USER',
  setAuthenticated = 'SET_AUTHENTICATED',
  setAuthToken = 'SET_TOKEN',
  setCurrentRole = 'SET_CURRENT_ROLE',
  setRefreshToken = 'SET_REFRESH_TOKEN',
  logout = 'LOGOUT',
  gameStarted = 'SET_GAME_STARTED',
  setUserWallet = 'SET_USER_WALLET',
  setTableDetails = 'SET_TABLE_DETAILS',
  setConfig = 'SET_CONFIG',
  setUserCards = 'SET_USER_CARDS',
  setCardsPerRound = 'SET_CARDS_PER_ROUND',
  setAuctionRefData = 'SET_AUCTION_REF_DATA',
}

export type AppAction =
  | { type: AppActionType.setCurrentUser; data: UserType }
  | { type: AppActionType.setAuthenticated; data: boolean }
  | { type: AppActionType.setAuthToken; data: string | null }
  | { type: AppActionType.setRefreshToken; data: string | null }
  | { type: AppActionType.setCurrentRole; data: string }
  | { type: AppActionType.gameStarted; data: boolean }
  | { type: AppActionType.setUserWallet; data: number }
  | { type: AppActionType.setTableDetails; data: TableDetailsType }
  | { type: AppActionType.setConfig; data: (ConfigType | null)[] }
  | { type: AppActionType.setUserCards; data: UserCards | null }
  | { type: AppActionType.setCardsPerRound; data: DistributedRounds | null }
  | { type: AppActionType.setAuctionRefData; data: AuctionState }
  | { type: AppActionType.logout };

export type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
  getUserSession: () => string | null;
  isAuthenticated: () => boolean;
  getRefreshToken: () => string | null;
  initializeAuth: (
    authToken: string | null,
    userData: UserType | null,
    refreshToken?: string | null,
  ) => void;
  getCurrentUser: () => void;
};
