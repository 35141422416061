import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { AppActionType, AppContextType } from '../../types/appContext.type';

const Logout = () => {
  const { dispatch } = useContext(AppContext) as AppContextType;

  useEffect(() => {
    sessionStorage.clear();
    dispatch({ type: AppActionType.logout });
    window.location.href = ROUTES.LOGIN;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
