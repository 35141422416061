import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { useContext, useEffect } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import MaintenancePage from './components/MaintenancePage';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import Conclusion from './modules/games/Conclusion';
import GamesList from './modules/games/GamesList';
import PlayGame from './modules/games/PlayGame';
import UploadPlayerCsv from './modules/games/UploadPlayerCsv';
import {
  GET_CONFIGURABLES,
  GET_USER_WALLET,
} from './modules/games/graphql/queries';
import { AppActionType, AppContextType } from './types/appContext.type';

type FallbackProps = {
  error: Error | null;
  componentStack: string | null;
};

const { Paragraph } = Typography;
const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES.LOGIN,
      element: <PublicRoute />,
      children: [{ path: ROUTES.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES.LOGOUT, element: <Logout /> }],
    },
  ];

  const GAME_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: '',
          element: <App />,
          children: [
            {
              index: true,
              element: <GamesList />,
            },
            {
              path: ROUTES.GAMES,
              children: [
                {
                  path: `${ROUTES.PLAY_GAME}`,
                  children: [
                    {
                      index: true,
                      element: <PlayGame />,
                    },
                    {
                      path: `${ROUTES.GAME_CONCLUSION}`,
                      element: <Conclusion />,
                    },
                  ],
                },
              ],
            },
            {
              path: ROUTES.PRIVATE,
              children: [
                {
                  path: `${ROUTES.PLAY_GAME}`,
                  children: [
                    {
                      index: true,
                      element: <PlayGame />,
                    },
                    {
                      path: `${ROUTES.GAME_CONCLUSION}`,
                      element: <Conclusion />,
                    },
                  ],
                },
              ],
            },
            {
              path: ROUTES.UPLOAD_CSV,
              children: [
                {
                  index: true,
                  element: <UploadPlayerCsv />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...GAME_MODULES,
    ...AUTH_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const [getUserWallet] = useLazyQuery(GET_USER_WALLET, {
    fetchPolicy: 'network-only',
  });
  useQuery(GET_CONFIGURABLES, {
    onCompleted: (response) => {
      if (response?.getConfig?.data) {
        dispatch({
          type: AppActionType.setConfig,
          data: response?.getConfig?.data,
        });
      }
    },
  });

  useEffect(() => {
    if (currentUser?.id) {
      getUserWallet({
        variables: { userId: currentUser?.id },
        onCompleted: (response) => {
          if (response?.getUserWallet?.data?.balance) {
            dispatch({
              type: AppActionType.setUserWallet,
              data: response?.getUserWallet?.data?.balance,
            });
          }
        },
      });
    }
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
  // if (!currentUser?.id && !window.location.href?.includes(ROUTES.LOGIN))
  //   return <LoaderComponent />;

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
